import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { push } from 'connected-react-router';
import * as userPostCardsActions from '../actions/userPostCardsActions';

import UserPostCardsComponent from '../components/UserPostCards';
import Paginator from '../components/Paginator';
import UserSinglePostCard from "../containers/UserSinglePostCard";

let defaultPage = 1;

class UserPostCards extends Component {
    componentDidMount() {
        this.props.loadUserPostCards( defaultPage );
    };

    onPageClick( pageNumber ) {
        this.props.loadUserPostCards( pageNumber );
    };

    render() {
        return  <Switch>
                    <Route exact path="/user-postcards" render={() => (
                        <div>
                            <UserPostCardsComponent userPostCards={ this.props.userPostCards.userPostCards }
                                                    openSinglePostCard={this.props.openSinglePostCard} />
                            <Paginator  onPageClick={( pageNumber ) => {this.onPageClick( pageNumber );}}
                                        pagesLength={this.props.userPostCards.paginator.count}
                                        currentPage={this.props.userPostCards.paginator.current} />
                        </div>
                    )} />
                    <Route path="/user-postcards/single-postcard" render={() => (
                        <UserSinglePostCard />
                    )} />
                </Switch>;
    };
}

const mapStateToProps = state => ({
    userPostCards: state.toJS().userPostCards,
});

const mapDispatchToProps = dispatch => ({
    loadUserPostCards: ( page ) => dispatch( userPostCardsActions.loadUserPostCards( {page} ) ),
    openSinglePostCard: ( cardId ) => dispatch( push('/user-postcards/single-postcard?' + cardId) ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPostCards);
