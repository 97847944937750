import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as userActions from '../actions/userActions';

import AuthComponent from '../components/Auth';

class Auth extends Component {
    render() {
        return  <div style={this.props.style}>
                    <AuthComponent
                        user={this.props.user}
                        registerUser={this.props.registerUser}
                        login={this.props.login}
                        setPurePhone={this.props.setPurePhone}
                        close={this.props.close} />
                </div>;
    };
}

const mapStateToProps = state => ({
    user: state.toJS().user,
});

const mapDispatchToProps = dispatch => ({
    registerUser: ( phone ) => dispatch( userActions.registerUser( {phone} ) ),
    login: ( phone, password ) => dispatch( userActions.login( {phone, password} ) ),
    setPurePhone: ( phone ) => dispatch( userActions.setPurePhone( {phone: phone} ) ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Auth);
