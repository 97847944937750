/**
 * New post card constants.
 */

export default {
    SET_CREATING_POSTCARD_ID: 'SET_CREATING_POSTCARD_ID',
    SET_CREATING_POSTCARD_IMAGE: 'SET_CREATING_POSTCARD_IMAGE',
    SET_CREATING_POSTCARD_IMAGE_ORIGINAL: 'SET_CREATING_POSTCARD_IMAGE_ORIGINAL',
    SET_PAPER_FORMATS: 'SET_PAPER_FORMATS',
    SET_PAPER_FORMAT_BY_USER: 'SET_PAPER_FORMAT_BY_USER',
    SET_PAPER_TYPES: 'SET_PAPER_TYPES',
    SET_PAPER_TYPE_BY_USER: 'SET_PAPER_TYPE_BY_USER',
    SET_FONTS: 'SET_FONTS',
    SET_FONT_BY_USER: 'SET_FONT_BY_USER',
    SET_FONT_COLORS: 'SET_FONT_COLORS',
    SET_FONT_COLOR_BY_USER: 'SET_FONT_COLOR_BY_USER',
    SET_CARD_TEXT: 'SET_CARD_TEXT',
    SET_CARD_TEXT_PREVIEW: 'SET_CARD_TEXT_PREVIEW',
    SET_RECIPIENT_ADDRESS_PART: 'SET_RECIPIENT_ADDRESS_PART',
    SET_SENDER_ADDRESS_PART: 'SET_SENDER_ADDRESS_PART',
    SET_ADDRESSES_LIST: 'SET_ADDRESSES_LIST',
    SET_SAVED_RECIPIENT_INFO: 'SET_SAVED_RECIPIENT_INFO',
    SET_SAVED_SENDER_INFO: 'SET_SAVED_SENDER_INFO',
    SET_POSTCARD_PREVIEW: 'SET_POSTCARD_PREVIEW',
    CLEAR_POSTCARD_PREVIEW: 'CLEAR_POSTCARD_PREVIEW',
    SET_POSTCARD_PREVIEW_ERRORS: 'SET_POSTCARD_PREVIEW_ERRORS',
    CLEAR_POSTCARD_PREVIEW_ERRORS: 'CLEAR_POSTCARD_PREVIEW_ERRORS',
    SET_FACE_IMAGE_BASE64: 'SET_FACE_IMAGE_BASE64',
    SET_BACK_IMAGE_BASE64: 'SET_BACK_IMAGE_BASE64',
    SET_UPLOADING_POSTCARD: 'SET_UPLOADING_POSTCARD',
    SET_UPLOADED_POSTCARD: 'SET_UPLOADED_POSTCARD',
    SET_POSTCARD_IS_UPLOADING: 'SET_POSTCARD_IS_UPLOADING',
    SET_POSTCARD_ORIENTATION: 'SET_POSTCARD_ORIENTATION',
};