/**
    STATUS_WITHOUT_ORDER = 5;    // без заказа
    STATUS_CREATED = 0;          // создан
    STATUS_PAYED = 1;            // оплачен
    STATUS_NOT_PAYED = 2;        // неудачная оплата (можно попробовать оплатить этот заказ повторно)
    STATUS_SHIPPING = 3;         // доставка
    STATUS_SUCCESS_SHIPPING = 4; // успешная доставка
    STATUS_WAITING_PAY = 6;      // обработка платежа
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as paymentsActions from '../actions/paymentsActions';

let loginListenerTimer;

class PaymentButton extends Component {
    constructor( props ) {
        super( props );
        this.state = {

        };
        this.resetPaymentData = this.resetPaymentData.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onButtonClickAction = this.onButtonClickAction.bind(this);
        this.onCheckStatusClick = this.onCheckStatusClick.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
        this.setOnLoginListener = this.setOnLoginListener.bind(this);
    };

    componentDidMount() {
        this.resetPaymentData();
    };

    componentWillUnmount() {
        clearInterval( loginListenerTimer );
    };

    resetPaymentData() {
        this.props.setPaymentURL( 'reset' );
        this.props.setPostCardAfterPaymentActionsData({});
        this.props.loadPaymentsWays();
    };

    onButtonClick( data ) {
        if ( this.props.isLogged ) {
            this.onButtonClickAction( data );
        } else {
            this.props.isNotLoggedAction();
            this.setOnLoginListener(() => {
                this.onButtonClickAction( data );
            });
        }
    };

    async onButtonClickAction( data ) {
        let self = this;
        await self.props.loadPaymentURL( {
            postCardId: self.props.postCard.id,
            priceId: data.priceId,
        } );
        if ( this.props.payments.paymentURL && this.props.payments.paymentURL !== 'failed' ) {
            window.location = this.props.payments.paymentURL;
        } else {
            this.checkStatus();
        }
    };

    async onCheckStatusClick( event ) {
        event.preventDefault();
        await this.checkStatus();
    };

    async checkStatus() {
        await this.props.loadPostCardAfterPaymentActionsData( this.props.postCard.id );
        if ( this.props.payments.postCardAfterPaymentsActionsData.status === 1 ) {
            if ( typeof this.props.onSuccessPayment === 'function' ) {
                this.props.onSuccessPayment();
            }
        }
    };

    setOnLoginListener( callback ) {
        loginListenerTimer = setInterval(() => {
            if ( this.props.isLogged ) {
                clearInterval( loginListenerTimer );
                if ( typeof callback === 'function' ) {
                    callback();
                }
            }
        }, 250);
    };

    render() {
        let postCard = this.props.postCard;
        let isPaySuccess = ( this.props.payments.postCardAfterPaymentsActionsData &&
            this.props.payments.postCardAfterPaymentsActionsData.status === 1 ) ||
            postCard.status === 1;
        let isPayWaiting = ( this.props.payments.postCardAfterPaymentsActionsData &&
            this.props.payments.postCardAfterPaymentsActionsData.status === 6 ) ||
            postCard.status === 6;
        let isPayFailed =
            ( this.props.payments.postCardAfterPaymentsActionsData &&
            this.props.payments.postCardAfterPaymentsActionsData.status === 2 ) ||
            postCard.status === 2 ||
            this.props.payments.paymentURL === 'failed';
        return (
            <div className={this.props.wrapperClassName || ''}>
                {isPaySuccess ? <div className="pay-success">Оплата прошла успешно</div> :
                    isPayWaiting ?  <div>
                                        <div className="pay-waiting">
                                            Платеж в ожидании, Вы можете проверить его статус, нажав на кнопку:
                                        </div>
                                        <a href="#" className="button"
                                           onClick={this.onCheckStatusClick}>
                                            Ожидание платежа. Проверить статус.
                                        </a>
                                    </div> :
                    <div>
                        {isPayFailed
                            ? <div className="pay-failed">Неудачная оплата, Вы можете попробовать еще раз</div>
                            : <div />}
                        {this.props.payments.paymentsWays.map(( item, index ) => {
                            return <a   href={this.props.href}
                                        className={this.props.className}
                                        key={index}
                                        onClick={( event ) => {
                                            event.preventDefault();
                                            this.onButtonClick({
                                                priceId: item.id,
                                                price: item.price
                                            });
                                        }}>{item.title}</a>;
                        })}
                    </div>}
            </div>
        );
    };
}

const mapStateToProps = state => ({
    payments: state.toJS().payments,
});

const mapDispatchToProps = dispatch => ({
    loadPaymentsWays: () => dispatch( paymentsActions.loadPaymentsWays() ),
    loadPaymentURL: async ( data ) => await dispatch( await paymentsActions.loadPaymentURL( {
        postCardId: data.postCardId,
        priceId: data.priceId,
    } ) ),
    setPaymentURL: ( paymentURL ) => dispatch( paymentsActions.setPaymentURL( {paymentURL} ) ),
    loadPostCardAfterPaymentActionsData: async ( postCardId ) => {
        await dispatch( await paymentsActions.loadPostCardAfterPaymentActionsData( {postCardId} ) );
    },
    setPostCardAfterPaymentActionsData: ( postCardAfterPaymentsActionsData ) => {
        dispatch( paymentsActions.setPostCardAfterPaymentActionsData( {postCardAfterPaymentsActionsData} ) );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentButton);
