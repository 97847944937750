import React, { Component } from 'react';

export default class BalloonLoader extends Component {
    render() {
        return (
            <div className="lds-css ng-scope">
                <div style={{width: '100%', height: '100%'}} className="lds-ellipsis">
                    <div>
                        <div></div>
                    </div>
                    <div>
                        <div></div>
                    </div>
                    <div>
                        <div></div>
                    </div>
                    <div>
                        <div></div>
                    </div>
                    <div>
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }
}