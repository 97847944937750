import paymentsConstants from '../constants/paymentsConstants';
import ServerApi from "../api/ServerApi";

export const setIsYandexFormOpen = data => ({
    type: paymentsConstants.SET_IS_YANDEX_FORM_OPEN,
    data: data,
});

export const setIsPaymentFrameOpen = data => ({
    type: paymentsConstants.SET_IS_PAYMENT_IFRAME_OPEN,
    data: data,
});

export const loadPaymentsWays = () => {
    return async dispatch => {
        let response = await ServerApi.getPaymentsWays();
        if ( response.success ) {
            dispatch( setPaymentsWays( {paymentsWays: response.data} ) );
        } else {
            console.log('loadPaymentsWays response.success false');
        }
    };
};

export const setPaymentsWays = data => ({
    type: paymentsConstants.SET_PAYMENTS_WAYS,
    data: data,
});

export const setPaymentToken = data => ({
    type: paymentsConstants.SET_PAYMENT_TOKEN,
    data: data,
});

export const loadPaymentURL = data => {
    return async dispatch => {
        let response = await ServerApi.getPaymentURL( data );
        if ( response.success ) {
            dispatch( setPaymentURL( {paymentURL: response.data.payment_url} ) );
        } else {
            dispatch( setPaymentURL( {paymentURL: response.data.payment_url} ) );
            console.log('loadPaymentURL response.success false');
        }
    };
};

export const setPaymentURL = data => ({
    type: paymentsConstants.SET_PAYMENT_URL,
    data: data,
});

export const loadPostCardAfterPaymentActionsData = data => {
    return async dispatch => {
        let response = await ServerApi.getPostCardAfterPaymentActionsData( data );
        if ( response.success ) {
            dispatch( setPostCardAfterPaymentActionsData( {
                postCardAfterPaymentsActionsData: response.data
            } ) );
        } else {
            console.log('loadPostCardAfterPaymentActionsData response.success false');
        }
    };
};

export const setPostCardAfterPaymentActionsData = data => ({
    type: paymentsConstants.SET_POST_CARD_AFTER_PAYMENT_ACTIONS_DATA,
    data: data,
});