import React, { Component } from 'react';
import Slider from 'react-slick';
import MockLoader from './MockLoader';

export default class CardCatalogue extends Component {
    isImageLoaded( url ) {
        return url.split(':')[0] === 'data';
    };

    renderDesktop() {
        let preloadedPostCards = this.props.preloadedPostCards;
        let sliderSettings = {
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            draggable: false,
        };
        return (
            <div className="preloaded-cards">
                {preloadedPostCards.postCards.map(( item, index ) => {
                    return (
                        <div key={index} className="slick-slider_custom">
                            <div className="slick-slider_custom__title">
                                {preloadedPostCards.categoriesNames[index]}
                            </div>
                            <Slider {...sliderSettings}>
                                {item.map(( item, indexDeep ) => {
                                    return (
                                        <div key={indexDeep} className="slick-slider_custom__slide-inner">
                                            <div className="preloaded-card"
                                                 style={{backgroundImage: "url(" + item.url + ")"}}
                                                 onClick={() => {
                                                     if ( this.isImageLoaded( item.url ) ) {
                                                         this.props.openPostCardCreation( item.id, item.url,
                                                                                                    item.originalUrl );
                                                     }
                                                 }} >
                                                {!this.isImageLoaded( item.url ) ? <MockLoader style={{
                                                    position: 'absolute',
                                                    margin: 'auto',
                                                    top: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                }} /> : ''}
                                            </div>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    );
                })}
            </div>
        );
    }

    renderMobile() {
        let preloadedPostCards = this.props.preloadedPostCards;
        return (
            <div className="preloaded-cards">
                {preloadedPostCards.postCards.map(( item, index ) => {
                    return (
                        <div key={index} className="preloaded-cards__inner">
                            <div className="preloaded-cards__title">
                                {preloadedPostCards.categoriesNames[index]}
                            </div>
                            <div className="preloaded-cards__row">
                                {item.map(( item, indexDeep ) => {
                                    return (
                                        <div className="preloaded-card" key={indexDeep}
                                             style={{backgroundImage: "url(" + item.url + ")"}}
                                             onClick={() => {
                                                 if ( this.isImageLoaded( item.url ) ) {
                                                     this.props.openPostCardCreation( item.id, item.url,
                                                                                                    item.originalUrl );
                                                 }
                                             }} >
                                            {!this.isImageLoaded( item.url ) ? <MockLoader style={{
                                                position: 'absolute',
                                                margin: 'auto',
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                            }} /> : ''}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        return window.isMobile || window.isTablet ? this.renderMobile() : this.renderDesktop();
    }
}