import ServerApi from '../api/ServerApi';
import newPostCardConstants from '../constants/newPostCardConstants';
import {push} from "connected-react-router";

export const setPaperFormats = data => ({
    type: newPostCardConstants.SET_PAPER_FORMATS,
    data: data,
});

export const setPaperFormatByUser = data => ({
    type: newPostCardConstants.SET_PAPER_FORMAT_BY_USER,
    data: data,
});

export const loadPaperFormats = () => {
    return async dispatch => {
        let response = await ServerApi.getPaperFormats();
        if ( response.success ) {
            dispatch( setPaperFormats( {paperFormats: response.data} ) );
            dispatch( setPaperFormatByUser( {paperFormat: response.data[0].id} ) );
        } else {
            console.log('loadPaperFormats response.success false');
        }
    };
};

export const setPaperTypes = data => ({
    type: newPostCardConstants.SET_PAPER_TYPES,
    data: data,
});

export const setPaperTypeByUser = data => ({
    type: newPostCardConstants.SET_PAPER_TYPE_BY_USER,
    data: data,
});

export const loadPaperTypes = () => {
    return async dispatch => {
        let response = await ServerApi.getPaperTypes();
        if ( response.success ) {
            dispatch( setPaperTypes( {paperTypes: response.data} ) );
            dispatch( setPaperTypeByUser( {paperType: response.data[0].id} ) );
        } else {
            console.log('loadPaperTypes response.success false');
        }
    };
};

export const setFonts = data => ({
    type: newPostCardConstants.SET_FONTS,
    data: data,
});

export const setFontByUser = data => ({
    type: newPostCardConstants.SET_FONT_BY_USER,
    data: data,
});

export const loadFonts = () => {
    return async dispatch => {
        let response = await ServerApi.getPostCardFonts();
        if ( response.success ) {
            dispatch( setFonts( {fonts: response.data} ) );
        } else {
            console.log('loadFonts response.success false');
        }
    };
};

export const setFontColors = data => ({
    type: newPostCardConstants.SET_FONT_COLORS,
    data: data,
});

export const setFontColorByUser = data => ({
    type: newPostCardConstants.SET_FONT_COLOR_BY_USER,
    data: data,
});

export const loadFontColors = () => {
    return async dispatch => {
        let response = await ServerApi.getPostCardFontColors();
        if ( response.success ) {
            dispatch( setFontColors( {fontColors: response.data} ) );
        } else {
            console.log('loadFontColors response.success false');
        }
    };
};

export const setCreatingPostcardId = data => ({
    type: newPostCardConstants.SET_CREATING_POSTCARD_ID,
    data: data,
});

export const setCreatingPostcardImage = data => ({
    type: newPostCardConstants.SET_CREATING_POSTCARD_IMAGE,
    data: data,
});

export const setCreatingPostcardImageOriginal = data => ({
    type: newPostCardConstants.SET_CREATING_POSTCARD_IMAGE_ORIGINAL,
    data: data,
});

export const setCardText = data => ({
    type: newPostCardConstants.SET_CARD_TEXT,
    data: data,
});

export const setCardTextPreview = data => ({
    type: newPostCardConstants.SET_CARD_TEXT_PREVIEW,
    data: data,
});

let loadPostCardTextPreviewTimeout;
export const loadPostCardTextPreview = ( data ) => {
    return async dispatch => {
        clearTimeout( loadPostCardTextPreviewTimeout );
        loadPostCardTextPreviewTimeout = setTimeout(async() => {
            let response = await ServerApi.getPostCardTextPreview( data );
            dispatch( setCardTextPreview( {cardTextPreview: response} ) );
        }, 1000);
    };
};

export const openSecondStep = () => {
    return dispatch => {
        dispatch( push('/card-creation/step-two') );
    };
};

export const setRecipientAddressPart = data => ({
    type: newPostCardConstants.SET_RECIPIENT_ADDRESS_PART,
    data: data,
});

export const setSenderAddressPart = data => ({
    type: newPostCardConstants.SET_SENDER_ADDRESS_PART,
    data: data,
});

let validateAddressTimeout;
export const validateAddress = ( data ) => {
    return async dispatch => {
        clearTimeout( validateAddressTimeout );
        validateAddressTimeout = setTimeout(async() => {
            let response = await ServerApi.validateAddress( data );
            if ( response.success ) {
                dispatch( setAddressesList( {addressesList: response.data} ) );
            } else {
                console.log('validateAddress response.success false');
            }
        }, 1000);
    };
};

export const setAddressesList = data => ({
    type: newPostCardConstants.SET_ADDRESSES_LIST,
    data: data,
});

let savedRecipientTimeout;
export const loadSavedRecipientInfo = ( data ) => {
    return async dispatch => {
        clearTimeout( savedRecipientTimeout );
        savedRecipientTimeout = setTimeout(async() => {
            let response = await ServerApi.getSavedRecipientInfo( data );
            if ( response.success ) {
                dispatch( setSavedRecipientInfo( {savedRecipientInfo: response.data} ) );
            } else {
                console.log('loadSavedRecipientInfo response.success false');
            }
        }, 1000);
    };
};

export const setSavedRecipientInfo = data => ({
    type: newPostCardConstants.SET_SAVED_RECIPIENT_INFO,
    data: data,
});

export const writeSavedSenderInfo = data => {
    return async dispatch => {
        window.localStorage.setItem('savedSenderInfo', JSON.stringify( data.savedSenderInfo ));
    };
};

export const loadSavedSenderInfo = ( data = {namePart: ''} ) => {
    return async dispatch => {
        let savedSenderInfo = JSON.parse( window.localStorage.getItem('savedSenderInfo') );
        if ( savedSenderInfo && savedSenderInfo.name.indexOf( data.namePart ) !== -1 ) {
            dispatch( setSavedSenderInfo( {savedSenderInfo: savedSenderInfo} ) );
        } else {
            dispatch( setSavedSenderInfo( {savedSenderInfo: {}} ) );
        }
    };
};

export const setSavedSenderInfo = data => ({
    type: newPostCardConstants.SET_SAVED_SENDER_INFO,
    data: data,
});

export const loadPostCardPreview = ( data ) => {
    return async dispatch => {
        let response = await ServerApi.getPostCardPreview( data );
        if ( response.success ) {
            dispatch( clearPostCardPreviewErrors() );
            dispatch( setPostCardPreview( {postCardPreview: response.data} ) );
            dispatch( setPostCardFaceImagePreviewBase64( { postCardPreview: response.data } ) );
            dispatch( setPostCardBackImagePreviewBase64( { postCardPreview: response.data } ) );
        } else {
            dispatch( setPostCardPreviewErrors( {postCardPreviewErrors: response.errors} ) );
            dispatch( clearPostCardPreview() );
            console.log('loadPostCardPreview response.success false');
        }
    };
};

export const setPostCardPreview = data => ({
    type: newPostCardConstants.SET_POSTCARD_PREVIEW,
    data: data,
});

export const clearPostCardPreview = data => ({
    type: newPostCardConstants.CLEAR_POSTCARD_PREVIEW,
    data: data,
});

export const setPostCardPreviewErrors = data => ({
    type: newPostCardConstants.SET_POSTCARD_PREVIEW_ERRORS,
    data: data,
});

export const clearPostCardPreviewErrors = data => ({
    type: newPostCardConstants.CLEAR_POSTCARD_PREVIEW_ERRORS,
    data: data,
});

export const setPostCardFaceImagePreviewBase64 = ( data ) => {
    return async dispatch => {
        let response = await ServerApi.getImageBase64( data.postCardPreview.face_url );
        dispatch( setFaceImageBase64({
            base64: response,
        }) );
    };
};

export const setFaceImageBase64 = data => ({
    type: newPostCardConstants.SET_FACE_IMAGE_BASE64,
    data: data,
});

export const setPostCardBackImagePreviewBase64 = ( data ) => {
    return async dispatch => {
        let response = await ServerApi.getImageBase64( data.postCardPreview.back_url );
        dispatch( setBackImageBase64({
            base64: response,
        }) );
    };
};

export const setBackImageBase64 = data => ({
    type: newPostCardConstants.SET_BACK_IMAGE_BASE64,
    data: data,
});

export const setUploadingPostCard = data => ({
    type: newPostCardConstants.SET_UPLOADING_POSTCARD,
    data: data,
});

export const uploadPostCard = ( data ) => {
    return async dispatch => {
        dispatch( setPostCardIsUploading( {isPostCardUploading: true} ) );
        let response = await ServerApi.uploadPostCard( data );
        if ( response.success ) {
            response.data.url = await ServerApi.getImageBase64( response.data.url );
            dispatch( setUploadedPostCard( {uploadedPostCard: response.data} ) );
            dispatch( setPostCardOrientation( {postCardOrientation: {
                angle: response.data.angle,
                isVertical: response.data.is_vertical,
            }} ) );
            dispatch( setCreatingPostcardId( {creatingPostCardId: response.data.id} ) );
            dispatch( setCreatingPostcardImage( {creatingPostCardImage: response.data.url} ) );
            dispatch( openPostCardCreation() );
        } else {
            console.log('uploadPostCard response.success false');
        }
        dispatch( setPostCardIsUploading( {isPostCardUploading: false} ) );
    };
};

export const setUploadedPostCard = data => ({
    type: newPostCardConstants.SET_UPLOADED_POSTCARD,
    data: data,
});

export const setPostCardIsUploading = data => ({
    type: newPostCardConstants.SET_POSTCARD_IS_UPLOADING,
    data: data,
});

export const setPostCardOrientation = data => ({
    type: newPostCardConstants.SET_POSTCARD_ORIENTATION,
    data: data,
});

export const patchPostCard = ( data ) => {
    return async dispatch => {
        let response = await ServerApi.patchPostCard( data );
        if ( response.success ) {

        } else {
            console.log('patchPostCard response.success false');
        }
    };
};

export const openPostCardCreation = () => {
    return dispatch => {
        dispatch( push('/card-creation/step-one') );
    };
};