import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as newPostCardActions from '../actions/newPostCardActions';

import ColorChooserComponent from '../components/ColorChooser';

class ColorChooser extends Component {
    constructor( props ) {
        super( props );
        this.state = {

        };
        this.setFontColorByUser = this.setFontColorByUser.bind(this);
    };

    async componentDidMount() {
        await this.props.loadFontColors();
        if ( !this.props.chosenFontColor ) {
            this.setFontColorByUser( this.props.fontColors[0].color );
        }
    };

    setFontColorByUser( color ) {
        this.props.setFontColorByUser( color );
        this.props.onChange( {color} );
    };

    render() {
        return <ColorChooserComponent fontColors={this.props.fontColors}
                                      setFontColorByUser={this.setFontColorByUser}
                                      chosenFontColor={this.props.chosenFontColor} />;
    };
}

const mapStateToProps = state => ({
    fontColors: state.toJS().newPostCard.creationData.fontColors,
    chosenFontColor: state.toJS().newPostCard.saveData.fontColor,
});

const mapDispatchToProps = dispatch => ({
    loadFontColors: async () => await dispatch( await newPostCardActions.loadFontColors() ),
    setFontColorByUser: ( fontColor ) => {
        dispatch( newPostCardActions.setFontColorByUser( {fontColor} ) );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ColorChooser);
