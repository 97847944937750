import userPostCardsConstants from '../constants/userPostCardsConstants';
import { fromJS, setIn, Map } from "immutable";

const initialState = fromJS({
    userPostCards: [],
    paginator: {},
});

export default function userPostCards(state = initialState, action) {
    let newState;
    switch (action.type) {
        case userPostCardsConstants.SET_USER_POSTCARDS:
            newState = setIn(state, ['userPostCards'], action.data.userPostCards);
            return newState;

        case userPostCardsConstants.SET_PAGINATOR:
            newState = setIn(state, ['paginator'], action.data.paginator);
            return newState;

        case userPostCardsConstants.SET_FACE_IMAGE_ORIGINAL_SINGLE:
            newState = state.set('userPostCards', state.get('userPostCards').map(( item ) => {
                if ( item.id === action.data.id ) {
                    item.face_url_original = action.data.originalImage;
                }
                return item;
            }));
            return newState;

        case userPostCardsConstants.SET_FACE_IMAGE_DIRECT_SINGLE:
            newState = state.set('userPostCards', state.get('userPostCards').map(( item ) => {
                if ( item.id === action.data.id ) {
                    item.face_url_direct = action.data.directImage;
                }
                return item;
            }));
            return newState;

        case userPostCardsConstants.SET_FACE_IMAGE_BASE_64_SINGLE:
            newState = state.set('userPostCards', state.get('userPostCards').map(( item ) => {
                if ( item.id === action.data.id ) {
                    item.face_url = action.data.base64;
                }
                return item;
            }));
            return newState;

        case userPostCardsConstants.SET_BACK_IMAGE_BASE_64_SINGLE:
            newState = state.set('userPostCards', state.get('userPostCards').map(( item ) => {
                if ( item.id === action.data.id ) {
                    item.back_url = action.data.base64;
                }
                return item;
            }));
            return newState;

        default:
            return state;
    }
};
