import React, { Component } from 'react';

export default class MockLoader extends Component {
    render() {
        return (
            <div className="round-loader" style={this.props.style}>
                <div className="round-loader__circle1" />
                <div className="round-loader__circle2" />
            </div>
        );
    }
}