import React, { Component } from 'react';

export default class OldBrowsersNotice extends Component {
    static detectIE() {
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf("MSIE ");

        return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    };

    render() {
        return OldBrowsersNotice.detectIE() ?
            <div className="old-browsers-notice">
                В данной версии браузера сервис может работать не корректно или же вовсе не будет
                работать. Пожалуйста, воспользуйтесь Google Chrome, Firefox, Opera, Edge.
            </div> : '';
    }
}