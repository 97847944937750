import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as newPostCardActions from '../actions/newPostCardActions';

import FontChooserComponent from '../components/FontChooser';

class FontChooser extends Component {
    constructor( props ) {
        super( props );
        this.getFontObjById = this.getFontObjById.bind(this);
        this.setFontByUser = this.setFontByUser.bind(this);
    };

    async componentDidMount() {
        await this.props.loadFonts();
        if ( !this.props.chosenFontId ) {
            this.setFontByUser( this.props.fonts[0].id );
        }
    };

    getFontObjById( fontId ) {
        let fontObj;
        for ( let key in this.props.fonts ) {
            if ( this.props.fonts[key].id === fontId ) {
                fontObj = this.props.fonts[key];
            }
        }
        return fontObj;
    };

    setFontByUser( font ) {
        this.props.setFontByUser( font );
        this.props.onChange( {font} );
    };

    render() {
        return <FontChooserComponent fonts={this.props.fonts}
                                     setFontByUser={this.setFontByUser}
                                     chosenFont={this.getFontObjById( this.props.chosenFontId )} />;
    };
}

const mapStateToProps = state => ({
    fonts: state.toJS().newPostCard.creationData.fonts,
    chosenFontId: state.toJS().newPostCard.saveData.font,
});

const mapDispatchToProps = dispatch => ({
    loadFonts: async () => await dispatch( await newPostCardActions.loadFonts() ),
    setFontByUser: ( font ) => {
        dispatch( newPostCardActions.setFontByUser( {font} ) );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FontChooser);
