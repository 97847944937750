import React, { Component } from 'react';
import {FacebookShareButton, OKShareButton, TwitterShareButton, VKShareButton} from "react-share";

export default class SocSharingButtons extends Component {
    render() {
        return (
            <div className="soc-sharing">
                <div className="soc-sharing__inner">
                    {this.props.content ?
                        <div className="soc-sharing__content">{this.props.content}</div> : <div />}
                    <div className="soc-sharing__buttons">
                        <div className="soc-sharing__title">Понравилось приложение?</div>
                        <div className="soc-sharing__description">Расскажи об этом друзьям!</div>
                        <VKShareButton
                            children={<div className="soc-sharing__item soc-sharing__item_vk" />}
                            title={this.props.sharingText}
                            url={this.props.url}
                            image={this.props.image} />
                        <FacebookShareButton
                            children={<div className="soc-sharing__item soc-sharing__item_fb" />}
                            quote={this.props.sharingText}
                            url={this.props.url} />
                        <TwitterShareButton
                            children={<div className="soc-sharing__item soc-sharing__item_tw" />}
                            title={this.props.sharingText}
                            url={this.props.url} />
                        <OKShareButton
                            children={<div className="soc-sharing__item soc-sharing__item_ok" />}
                            title={this.props.sharingText}
                            url={this.props.url}
                            image={this.props.image} />
                    </div>
                </div>
            </div>
        );
    }
}