import Config from "../config/Config";

import React, { Component } from 'react';

import PaymentButton from '../containers/PaymentButton';
import SocSharing from '../containers/SocSharing';

export default class UserSinglePostCard extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            isPopupOpen: false,
            popupShownUrl: '',
            isFaceImageChosen: false,
            isSocSharingVisible: false,
        };
        this.showPopup = this.showPopup.bind(this);
        this.hidePopup = this.hidePopup.bind(this);
        this.onClickFaceImage = this.onClickFaceImage.bind(this);
        this.onClickBackImage = this.onClickBackImage.bind(this);
        this.showSocSharing = this.showSocSharing.bind(this);
        this.hideSocSharing = this.hideSocSharing.bind(this);
        this.renderPopup = this.renderPopup.bind(this);
    };

    showPopup() {
        this.setState({isPopupOpen: true});
    };

    hidePopup() {
        this.setState({isPopupOpen: false});
    };

    onClickFaceImage( url ) {
        this.setState({popupShownUrl: url});
        this.setState({isFaceImageChosen: true});
        this.showPopup();
    };

    onClickBackImage( url ) {
        this.setState({popupShownUrl: url});
        this.setState({isFaceImageChosen: false});
        this.showPopup();
    };

    showSocSharing() {
          this.setState({isSocSharingVisible: true});
    };

    hideSocSharing() {
          this.setState({isSocSharingVisible: false});
    };

    renderPopup() {
        let postCard = this.props.postCard;
        let isHorizontal =  postCard &&
                            //null если открытка предзагружена
                            postCard.image.angle !== null &&
                            postCard.image.is_vertical !== null &&
                            !postCard.image.is_vertical &&
                            this.state.isFaceImageChosen;
        return (
            <div className={"image-card-popup " + ( isHorizontal ? 'image-card-popup_h' : '' )}>
                <div className="image-card-popup__w">
                    <div className="image-card-popup__img-outer">
                        <div className="image-card-popup__closer" onClick={this.hidePopup} />
                        <img src={this.state.popupShownUrl} alt="" />
                    </div>
                </div>
            </div>
        );
    };

    renderDesktop() {
        let postCard = this.props.postCard;
        if ( !postCard ) {
            return <div className="wrapper-center">К сожалению, такая открытка не найдена.</div>;
        }
        let needToShareImage = !!postCard.image.category_id;
        return (
            <div className="card-fields-part clearfix">
                <div className="card-fields-part__preview card-fields-part__preview_center">
                    <div>
                        <div className={"card-preview " +
                                                    //null если открытка предзагружена
                                                    ( postCard.image.angle !== null &&
                                                    postCard.image.is_vertical !== null && !postCard.image.is_vertical
                                                                                            ? 'card-preview_h' : '' )}>
                            <div className="card-preview__title">Открытка</div>
                            <div className="card-preview__wrapper">
                                <div className="card-preview__wrapper__item">

                                    <div className="card-preview__face">
                                        <div className="card-preview__face__image"
                                             onClick={() => {
                                                 this.onClickFaceImage( postCard.face_url );
                                             }}
                                             style={{backgroundImage: "url(" + postCard.face_url + ")"}} />
                                    </div>

                                </div>
                                <div className="card-preview__wrapper__item">

                                    <div className="card-preview__back">
                                        <div className="card-preview__back__image"
                                             onClick={() => {
                                                 this.onClickBackImage( postCard.back_url );
                                             }}
                                             style={{backgroundImage: "url(" + postCard.back_url + ")"}} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="clearfix" />

                {postCard.status === 1 || postCard.status === 3 || postCard.status === 4 ?
                    <div className="wrapper-center mb18">
                        <a href="#" className="button" onClick={( event ) => {
                            event.preventDefault();
                            this.showSocSharing();
                        }}>
                            Поделиться
                        </a>
                    </div>: '' }

                {postCard.status !== 3 && postCard.status !== 4 ?
                    <PaymentButton
                        href="/" wrapperClassName="wrapper-center" className="button" postCard={postCard}
                        isLogged={true} isNotLoggedAction={() => {}} /> : ''}

                {this.state.isPopupOpen ? this.renderPopup() : <div />}

                <SocSharing
                    isVisible={this.state.isSocSharingVisible}
                    content={
                        needToShareImage
                            ? <div className={'sharing-postcard'}
                                   style={{backgroundImage: 'url(' + postCard.face_url + ')'}} />
                            : <div className={'sharing-postcard'}
                                   style={{backgroundImage: 'url(/mobileSplashScreen.jpg)'}} />
                    }
                    image={
                        //шарим только если изображение предзагружено, иначе шарим заглушку
                        needToShareImage
                            ? postCard.face_url_direct
                            : Config.domain + '/mobileSplashScreen.jpg'} />
            </div>
        );
    }

    renderMobile() {
        let postCard = this.props.postCard;
        if ( !postCard ) {
            return <div className="wrapper-center">К сожалению, такая открытка не найдена.</div>;
        }
        let needToShareImage = !!postCard.image.category_id;
        return (
            <div className="card-fields-part clearfix">
                <div className="card-fields-part__preview">
                    <div>
                        <div className={"card-preview " +
                                                    //null если открытка предзагружена
                                                    ( postCard.image.angle !== null &&
                                                    postCard.image.is_vertical !== null && !postCard.image.is_vertical
                                                                                            ? 'card-preview_h' : '' )}>
                            <div className="card-preview__title">Открытка</div>
                            <div className="card-preview__wrapper">
                                <div className="card-preview__wrapper__item">

                                    <div className="card-preview__face">
                                        <div className="card-preview__face__image"
                                             onClick={() => {
                                                 this.onClickFaceImage( postCard.face_url );
                                             }}
                                             style={{backgroundImage: "url(" + postCard.face_url + ")"}} />
                                    </div>

                                </div>
                                <div className="card-preview__wrapper__item">

                                    <div className="card-preview__back">
                                        <div className="card-preview__back__image"
                                             onClick={() => {
                                                 this.onClickBackImage( postCard.back_url );
                                             }}
                                             style={{backgroundImage: "url(" + postCard.back_url + ")"}} />
                                    </div>

                                    {postCard.status === 1 || postCard.status === 3 || postCard.status === 4 ?
                                        <div className="wrapper-center mb18">
                                            <a href="#" className="button" onClick={( event ) => {
                                                event.preventDefault();
                                                this.showSocSharing();
                                            }}>
                                                Поделиться
                                            </a>
                                        </div>: '' }

                                    {postCard.status !== 3 && postCard.status !== 4 ?
                                        <PaymentButton href="/" wrapperClassName=""
                                                       className="button pl5 pr5 width-full"
                                                       postCard={postCard} isLogged={true}
                                                       isNotLoggedAction={() => {}} /> : ''}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isPopupOpen ? this.renderPopup() : <div />}

                <SocSharing
                    isVisible={this.state.isSocSharingVisible}
                    content={
                        needToShareImage
                            ? <div className={'sharing-postcard'}
                                   style={{backgroundImage: 'url(' + postCard.face_url + ')'}} />
                            : <div className={'sharing-postcard'}
                                   style={{backgroundImage: 'url(/mobileSplashScreen.jpg)'}} />
                    }
                    image={
                        //шарим только если изображение предзагружено, иначе шарим заглушку
                        needToShareImage
                            ? postCard.face_url_direct
                            : Config.domain + '/mobileSplashScreen.jpg'} />
            </div>
        );
    }

    render() {
        return window.isMobile || window.isTablet ? this.renderMobile() : this.renderDesktop();
    }
}