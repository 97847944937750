import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { push, goBack } from 'connected-react-router';
import * as newPostCardActions from '../actions/newPostCardActions';
import * as userActions from "../actions/userActions";

import CardCreationStepOne from '../components/CardCreationStepOne';
import CardCreationStepTwo from '../components/CardCreationStepTwo';

let validationInterval;//костылище
let loadPostCardPreviewInterval;

class CardCreation extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            isValidFirstStepForm: false,
            isValidAddressesForm: false,
            isPostCardUpdating: false,
            firstStepFieldsValidateParams: {},
        };
        this.validateFirstStepFields = this.validateFirstStepFields.bind(this);
        this.validateAddresses = this.validateAddresses.bind(this);
        this.reloadPostCardWithTimeProtection = this.reloadPostCardWithTimeProtection.bind(this);
    };

    componentDidMount() {
        if ( !this.props.newPostCard.saveData.creatingPostCardId ) {
            window.location = '/'
        }

        this.props.loadPaperFormats();
        this.props.loadPaperTypes();
        validationInterval = setInterval(() => {
            this.validateFirstStepFields();
            let isValidAddressesFormPrev = this.state.isValidAddressesForm;
            this.validateAddresses();
        }, 500);
    };

    componentWillUnmount() {
        clearInterval( validationInterval );
    };

    validateFirstStepFields() {
        let validateParams = {
            isPaperTypeChosen: !!this.props.newPostCard.saveData.paperType,
            isPaperFormatChosen: !!this.props.newPostCard.saveData.paperFormat,
            isFontChosen: !!this.props.newPostCard.saveData.font,
            isFontColorChosen: !!this.props.newPostCard.saveData.fontColor,
            isCardTextTyped: !!this.props.newPostCard.saveData.cardText,
            isCardTextRowsAmountValid: !!( (this.props.newPostCard.saveData.cardText || '').split('\n').length <= 22 ),
        };

        let validateRule    =  validateParams.isPaperTypeChosen
                            && validateParams.isPaperFormatChosen
                            && validateParams.isFontChosen
                            && validateParams.isFontColorChosen
                            && validateParams.isCardTextTyped
                            && validateParams.isCardTextRowsAmountValid;
        if ( this.state.isValidFirstStepForm !== validateRule ) {
            this.setState({isValidFirstStepForm: validateRule});
            this.setState({firstStepFieldsValidateParams: {
                isPaperTypeChosen: validateParams.isPaperTypeChosen,
                isPaperFormatChosen: validateParams.isPaperFormatChosen,
                isFontChosen: validateParams.isFontChosen,
                isFontColorChosen: validateParams.isFontColorChosen,
                isCardTextTyped: validateParams.isCardTextTyped,
                isCardTextRowsAmountValid: validateParams.isCardTextRowsAmountValid,
            }});
        }
        return validateRule;
    };

    validateAddresses() {
        if ( this.props.newPostCard.saveData.recipientAddress && this.props.newPostCard.saveData.senderAddress ) {
            let validateRecipientParams = {
                isName: !!this.props.newPostCard.saveData.recipientAddress.name,
                isRegion: !!this.props.newPostCard.saveData.recipientAddress.region,
                isCity: !!this.props.newPostCard.saveData.recipientAddress.city,
                isStreet: !!this.props.newPostCard.saveData.recipientAddress.street,
                isIndex: !!this.props.newPostCard.saveData.recipientAddress.index,
                isNumber: !!this.props.newPostCard.saveData.recipientAddress.number,
            };

            let validateSenderParams = {
                isName: !!this.props.newPostCard.saveData.senderAddress.name,
                isRegion: !!this.props.newPostCard.saveData.senderAddress.region,
                isCity: !!this.props.newPostCard.saveData.senderAddress.city,
                isStreet: !!this.props.newPostCard.saveData.senderAddress.street,
                isIndex: !!this.props.newPostCard.saveData.senderAddress.index,
                isNumber: !!this.props.newPostCard.saveData.senderAddress.number,
            };

            let validateRule =
                validateRecipientParams.isName && validateRecipientParams.isRegion &&
                validateRecipientParams.isCity && validateRecipientParams.isStreet &&
                validateRecipientParams.isIndex && validateRecipientParams.isNumber
                &&
                validateSenderParams.isName && validateSenderParams.isRegion &&
                validateSenderParams.isCity && validateSenderParams.isStreet &&
                validateSenderParams.isIndex && validateSenderParams.isNumber;

            if ( this.state.isValidAddressesForm !== validateRule ) {
                this.setState({isValidAddressesForm: validateRule});
            }
            return validateRule;
        }
        return false;
    };

    reloadPostCardWithTimeProtection() {
        clearTimeout( loadPostCardPreviewInterval );
        this.setState({isPostCardUpdating: true});
        loadPostCardPreviewInterval = setTimeout(async () => {
            if ( !!this.props.newPostCard.creationData.postCardPreview ||
                                this.props.newPostCard.creationData.postCardPreview === null ) {
                //потому что после очистки ставится именно в null, а нам нужно чтобы
                // как минимум раз превью было успешно загружено

                if ( this.props.newPostCard.saveData.postCardOrientation &&
                                                    'angle' in this.props.newPostCard.saveData.postCardOrientation ) {
                    await this.props.patchPostCard( {
                        id: this.props.newPostCard.saveData.creatingPostCardId,
                        angle: this.props.newPostCard.saveData.postCardOrientation.angle,
                    } );
                }

                this.props.loadPostCardPreview(this.props.newPostCard.saveData);
                setTimeout(() => {this.setState({isPostCardUpdating: false});}, 1000);
            }
        }, 3000);
    };

    render() {
        return  <Switch>
                    <Route exact path="/card-creation/step-one" render={() => (
                        <CardCreationStepOne
                            newPostCard={this.props.newPostCard}
                            setPaperFormatByUser={this.props.setPaperFormatByUser}
                            setPaperTypeByUser={this.props.setPaperTypeByUser}
                            onChangeCardFontSettings={( data ) => {
                                if ( typeof data.text === 'string' ) {
                                    this.props.setCardText( data.text );
                                }
                                setTimeout(() => {
                                    if ( this.props.newPostCard.saveData.cardText ) {
                                        this.props.loadPostCardTextPreview( {
                                            font: this.props.newPostCard.saveData.font,
                                            fontColor: this.props.newPostCard.saveData.fontColor,
                                            cardText: this.props.newPostCard.saveData.cardText,
                                        } );
                                    }
                                }, 0);
                            }}
                            rotateLeft={() => {
                                let calculatedAngle = this.props.newPostCard.saveData.postCardOrientation.angle - 90;
                                let angle = calculatedAngle < 0 ? 270 : calculatedAngle;
                                this.props.setPostCardOrientation( {postCardOrientation: {
                                    angle: angle,
                                    isVertical: this.props.newPostCard.saveData.postCardOrientation.isVertical,
                                }} )
                            }}
                            rotateRight={() => {
                                let angle = ( this.props.newPostCard.saveData.postCardOrientation.angle + 90 ) % 360;
                                this.props.setPostCardOrientation( {postCardOrientation: {
                                    angle: angle,
                                    isVertical: this.props.newPostCard.saveData.postCardOrientation.isVertical,
                                }} )
                            }}
                            openSecondStep={this.props.openSecondStep}
                            isValidFirstStepForm={this.state.isValidFirstStepForm}
                            firstStepFieldsValidateParams={this.state.firstStepFieldsValidateParams} />
                    )} />
                    <Route exact path="/card-creation/step-two" render={() => (
                        <CardCreationStepTwo
                            newPostCard={this.props.newPostCard}
                            onComponentDidMount={() => {
                                this.props.loadSavedRecipientInfo();
                                this.props.loadSavedSenderInfo();
                                this.reloadPostCardWithTimeProtection();
                            }}
                            onChangeRecipient={( field, value ) => {
                                if ( field === 'name' ) {
                                    this.props.loadSavedRecipientInfo( {namePart: value} );
                                }
                                this.props.setRecipientAddressPart( {field, value} );
                                setTimeout(() => {
                                    this.props.validateAddress( this.props.newPostCard.saveData.recipientAddress );
                                }, 0);
                                this.reloadPostCardWithTimeProtection();
                            }}
                            onChangeSender={( field, value ) => {
                                if ( field === 'name' ) {
                                    this.props.loadSavedSenderInfo( {namePart: value} );
                                }
                                this.props.setSenderAddressPart( {field, value} );
                                setTimeout(() => {
                                    this.props.validateAddress( this.props.newPostCard.saveData.senderAddress );
                                }, 0);
                                this.reloadPostCardWithTimeProtection();
                            }}
                            onClickNext={async() => {
                                clearTimeout( loadPostCardPreviewInterval );
                                this.setState({isPostCardUpdating: true});
                                window.isMobile
                                    ? window.scrollTo(0, document.body.scrollHeight) : window.scrollTo(0, 0);
                                if ( this.props.newPostCard.saveData.postCardOrientation &&
                                                    'angle' in this.props.newPostCard.saveData.postCardOrientation ) {
                                    await this.props.patchPostCard( {
                                        id: this.props.newPostCard.saveData.creatingPostCardId,
                                        angle: this.props.newPostCard.saveData.postCardOrientation.angle,
                                    } );
                                }
                                await this.props.loadPostCardPreview(this.props.newPostCard.saveData);
                                window.isMobile
                                    ? window.scrollTo(0, document.body.scrollHeight) : window.scrollTo(0, 0);
                                setTimeout(() => {this.setState({isPostCardUpdating: false});}, 1000);
                            }}
                            onClickBack={this.props.goBack}
                            isValidAddressesForm={this.state.isValidAddressesForm}
                            isPostCardUpdating={this.state.isPostCardUpdating}
                            onPaymentClickIfNotLogged={() => {
                                this.props.setAuthPopupVisibility( {isAuthPopupVisible: true} );
                            }}
                            isLogged={this.props.isLogged}
                            payments={this.props.payments} />
                        )} />
                </Switch>;
    };
}

const mapStateToProps = state => ({
    newPostCard: state.toJS().newPostCard,
    isLogged: !!state.toJS().user.userToken,
    payments: state.toJS().payments,
});

const mapDispatchToProps = dispatch => ({
    loadPaperFormats: () => dispatch( newPostCardActions.loadPaperFormats() ),
    setPaperFormatByUser: ( paperFormat ) => {
        dispatch( newPostCardActions.setPaperFormatByUser( {paperFormat} ) );
    },
    loadPaperTypes: () => dispatch( newPostCardActions.loadPaperTypes() ),
    setPaperTypeByUser: ( paperType ) => {
        dispatch( newPostCardActions.setPaperTypeByUser( {paperType} ) );
    },
    setCardText: ( cardText ) => dispatch( newPostCardActions.setCardText( {cardText} ) ),
    loadPostCardTextPreview: ( data ) => {
        dispatch( newPostCardActions.loadPostCardTextPreview( {
            font: data.font,
            fontColor: data.fontColor,
            cardText: data.cardText,
        } ) );
    },
    openSecondStep: () => dispatch( newPostCardActions.openSecondStep() ),
    goBack: () => dispatch( goBack() ),
    setRecipientAddressPart: ( data ) => dispatch( newPostCardActions.setRecipientAddressPart( data ) ),
    setSenderAddressPart: ( data ) => dispatch( newPostCardActions.setSenderAddressPart( data ) ),
    validateAddress: ( data ) => dispatch( newPostCardActions.validateAddress( data ) ),
    loadSavedRecipientInfo: ( data ) => dispatch( newPostCardActions.loadSavedRecipientInfo( data ) ),
    loadSavedSenderInfo: ( data ) => dispatch( newPostCardActions.loadSavedSenderInfo( data ) ),
    loadPostCardPreview: ( data ) => {
        dispatch( newPostCardActions.loadPostCardPreview( data ) );
        dispatch( newPostCardActions.writeSavedSenderInfo( {savedSenderInfo: data.senderAddress} ) );
    },
    setPostCardOrientation: ( data ) => dispatch( newPostCardActions.setPostCardOrientation( data ) ),
    patchPostCard: ( data ) => dispatch( newPostCardActions.patchPostCard( data ) ),
    setAuthPopupVisibility: ( data ) => dispatch( userActions.setAuthPopupVisibility( data ) ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardCreation);
