import preloadedPostCardsConstants from '../constants/preloadedPostCardsConstants';
import { Map } from 'immutable';
import { fromJS } from "immutable";

const initialState = fromJS({
    items: [],
});

export default function preloadedPostCards(state = initialState, action) {
    let newState;
    switch (action.type) {
        case preloadedPostCardsConstants.SET_POST_CARDS:
            newState = state.set('items', action.data.postCards);
            return newState;

        case preloadedPostCardsConstants.SET_IMAGE_BASE64:
            newState = state.set('items', state.get('items').map(( item ) => {
                if ( item.id === action.data.id ) {
                    item.originalUrl = item.url;
                    item.url = action.data.base64;
                }
                return item;
            }));
            return newState;

        default:
            return state;
    }
};
