import paymentsConstants from '../constants/paymentsConstants';
import { Map } from 'immutable';
import { fromJS } from "immutable";

const initialState = fromJS({
    isYandexFormOpen: false,
    isPaymentIframeOpen: false,
    paymentsWays: [],
    paymentToken: '',
    paymentURL: '',
    postCardAfterPaymentsActionsData: {},
});

export default function payments(state = initialState, action) {
    let newState;
    switch (action.type) {
        case paymentsConstants.SET_IS_YANDEX_FORM_OPEN:
            newState = state.set('isYandexFormOpen', action.data.isYandexFormOpen);
            return newState;

        case paymentsConstants.SET_IS_PAYMENT_IFRAME_OPEN:
            newState = state.set('isPaymentIframeOpen', action.data.isPaymentIframeOpen);
            return newState;

        case paymentsConstants.SET_PAYMENTS_WAYS:
            newState = state.set('paymentsWays', action.data.paymentsWays);
            return newState;

        case paymentsConstants.SET_PAYMENT_TOKEN:
            newState = state.set('paymentToken', action.data.paymentToken);
            return newState;

        case paymentsConstants.SET_PAYMENT_URL:
            let paymentUrl;
            if ( !action.data.paymentURL ) {
                paymentUrl = 'failed';
            } else if ( action.data.paymentURL === 'reset' ) {
                paymentUrl = '';
            } else {
                paymentUrl = action.data.paymentURL;
            }
            newState = state.set('paymentURL', paymentUrl);
            return newState;

        case paymentsConstants.SET_POST_CARD_AFTER_PAYMENT_ACTIONS_DATA:
            newState = state.set('postCardAfterPaymentsActionsData', action.data.postCardAfterPaymentsActionsData);
            return newState;

        default:
            return state;
    }
};
