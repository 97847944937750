import Config from '../config/Config';

import React, { Component } from 'react';

export default class MockDownloadApps extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            isVisible: false,
        };
        this.showMock = this.showMock.bind(this);
        this.hideMock = this.hideMock.bind(this);
    };

    componentDidMount() {
        this.runMock();
    };

    runMock() {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let mockLastShownDate = new Date( +localStorage.getItem('mockLastShownDate') );
        if ( today.getTime() !== mockLastShownDate.getTime() ) {
            this.showMock();
            localStorage.setItem('mockLastShownDate', +today);
        }
    };

    showMock() {
        this.setState({isVisible: true});
    };

    hideMock() {
        this.setState({isVisible: false});
    };

    render() {
        return this.state.isVisible ?
            <div className="mock-download-apps">
                <div className="mock-download-apps__content">
                    <div className="mock-download-apps__title">
                        Салют! Открытки<br />
                        С Приложением еще проще
                    </div>
                    <a href={Config.mobileAppsUrl} className="button-yellow">Установить</a>
                    <br />
                    <a href="#" className="yellow" onClick={( event ) => {
                        event.preventDefault();
                        this.hideMock();
                    }
                    }>Спасибо, не нужно</a>
                </div>
            </div> : '';
    }
}