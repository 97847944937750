import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { push } from 'connected-react-router';

import UserSinglePostCardComponent from '../components/UserSinglePostCard';

class UserSinglePostCard extends Component {
    getSinglePostCard() {
        let cardId = +this.props.search.split('?').join('');
        let postCards = this.props.userPostCards.userPostCards;
        for ( let i = 0; i < postCards.length; i++ ) {
            if ( postCards[i].id === cardId ) {
                return postCards[i];
            }
        }
        return null;
    };

    render() {
        return  <UserSinglePostCardComponent postCard={this.getSinglePostCard()} />;
    };
}

const mapStateToProps = state => ({
    userPostCards: state.toJS().userPostCards,
    search: state.toJS().router.location.search,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSinglePostCard);
