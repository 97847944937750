import Config from './config/Config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { push, replace } from 'connected-react-router';
import * as userActions from './actions/userActions';
import './css/App.css';
import './css/AppMobile.css';
import './css/vendor/slick.min.css';
import './css/vendor/slick-theme.min.css';

import MobileDetect from 'mobile-detect';

import CardCatalogue from './containers/CardCatalogue';
import CardCreation from './containers/CardCreation';
import Auth from './containers/Auth';
import CardUploader from './containers/CardUploader';
import UploadPanel from './containers/UploadPanel';
import UserPostCards from './containers/UserPostCards';
import AboutService from './containers/AboutService';
import MockDownloadApps from './containers/MockDownloadApps';

import OldBrowsersNotice from './components/OldBrowsersNotice';

let mobileType = window.mobileType = new MobileDetect( window.navigator.userAgent ).phone();
let isMobile = window.isMobile = !!mobileType;

let tabletType = window.tabletType = new MobileDetect( window.navigator.userAgent ).tablet();
let isTablet = window.isTablet = !!tabletType;

class App extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            isMenuOpen: false,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.openUploaderFromOutside = this.openUploaderFromOutside.bind(this);
        this.setRedirects = this.setRedirects.bind(this);
    };

    componentDidMount() {
        this.setRedirects( Config.redirectConfig );
        this.props.getGuestToken();
        this.props.restoreUserToken();
    };

    toggleMenu() {
        this.setState({isMenuOpen: !this.state.isMenuOpen});
    };

    closeMenu() {
        this.setState({isMenuOpen: false});
    };

    onMenuItemClick( event ) {
        event.preventDefault();
        this.closeMenu();
    };

    openUploaderFromOutside() {
        document.querySelector('.js-upload-btn').click();
        document.querySelector('.js-upload-btn .chooseFileButton ').click();
    };

    setRedirects( config ) {
        config.forEach(( item ) => {
            if ( this.props.pathname === item.url ) {
                this.props.redirect( item.targetUrl );
            }
        });
    };

    renderDesktop() {
        return this.props.user.token ? (
            <div className="page-wrapper">
                <OldBrowsersNotice />
                <header className="header clearfix">
                    <a href="/" className="logo">
                        <span className="logo__title">Салют! Открытки</span>
                        <span className="logo__about">Сервис доставки открыток по почте</span>
                    </a>
                    <div className="header__controls">
                        <a href="/" className="i-link i-link_about" onClick={( event ) => {
                            this.onMenuItemClick( event );
                            this.props.openAboutService();
                        }}>О сервисе</a>
                        <a href="/" className="i-link i-link_postcards"
                           onClick={(event) => {
                               this.onMenuItemClick( event );
                               !this.props.user.userToken
                                   ? this.props.setAuthPopupVisibility( {isAuthPopupVisible: true} )
                                   : this.props.openUserPostCards();
                           }}>
                            Список открыток
                        </a>
                        {!this.props.user.userToken ?
                            <a href="/" className="i-link i-link_login"
                               onClick={( event ) => {
                                   this.onMenuItemClick( event );
                                   this.props.setAuthPopupVisibility( {isAuthPopupVisible: true} );
                               }}>
                                Войти
                            </a> :
                            <a href="/" className="i-link i-link_login"
                               onClick={( event ) => {
                                   this.onMenuItemClick( event );
                                   this.props.logout();
                               }}>Выйти</a>
                        }
                    </div>
                    <Switch>
                        <Route exact path={"(/|/about-service)"} render={() => (
                            <CardUploader />
                        )} />
                    </Switch>
                </header>

                <div className="header-filler" />

                <Switch>
                    <Route exact path="/" render={() => (
                        <div>
                            <UploadPanel onClick={this.openUploaderFromOutside} />
                            <CardCatalogue />
                        </div>
                    )} />

                    <Route path="/card-creation/" render={() => (
                        <CardCreation />
                    )} />

                    <Route path="/user-postcards/" render={() => (
                        <UserPostCards />
                    )} />

                    <Route path="/about-service/" render={() => (
                        <div>
                            <UploadPanel onClick={this.openUploaderFromOutside} />
                            <AboutService />
                        </div>
                    )} />
                </Switch>

                <Auth style={{display: this.props.user.isAuthPopupVisible ? 'block' : 'none'}}
                      close={() => this.props.setAuthPopupVisibility( {isAuthPopupVisible: false} )} />
            </div>
        ) : <div />;
    }

    renderMobile() {
        return this.props.user.token ? (
            <div className={"page-wrapper" + ( isMobile || isTablet ? " is-mobile" : '' )}>
                <header className="header clearfix">
                    <a href="/" className="logo">
                        <span className="logo__title">Салют! Открытки</span>
                        <span className="logo__about">Сервис доставки открыток по почте</span>
                    </a>
                    <div className={"menu-btn " + ( this.state.isMenuOpen ? 'active' : '' )} onClick={this.toggleMenu}/>
                    <div className={"header__controls " + ( this.state.isMenuOpen ? 'active' : '' )}>
                        <a href="/" className="i-link i-link_about" onClick={( event ) => {
                            this.onMenuItemClick( event );
                            this.props.openAboutService();
                        }}>О сервисе</a>
                        <a href="/" className="i-link i-link_postcards"
                           onClick={(event) => {
                               this.onMenuItemClick( event );
                               !this.props.user.userToken
                                   ? this.props.setAuthPopupVisibility( {isAuthPopupVisible: true} )
                                   : this.props.openUserPostCards();
                           }}>
                            Список открыток
                        </a>
                        {!this.props.user.userToken ?
                            <a href="/" className="i-link i-link_login"
                               onClick={( event ) => {
                                   this.onMenuItemClick( event );
                                   this.props.setAuthPopupVisibility( {isAuthPopupVisible: true} );
                               }}>
                                Войти
                            </a> :
                            <a href="/" className="i-link i-link_login"
                               onClick={( event ) => {
                                   this.onMenuItemClick( event );
                                   this.props.logout();
                               }}>Выйти</a>
                        }
                    </div>
                </header>

                <div className="header-filler" />

                <Switch>
                    <Route exact path="/" render={() => (
                        <div className="relative">
                            <UploadPanel onClick={this.openUploaderFromOutside} />
                            <CardUploader className="relative" />
                            <CardCatalogue />
                        </div>
                    )} />

                    <Route path="/card-creation/" render={() => (
                        <CardCreation />
                    )} />

                    <Route path="/user-postcards/" render={() => (
                        <UserPostCards />
                    )} />

                    <Route path="/about-service/" render={() => (
                        <div>
                            <UploadPanel onClick={this.openUploaderFromOutside} />
                            <CardUploader className="relative" />
                            <AboutService />
                        </div>
                    )} />
                </Switch>

                <Auth style={{display: this.props.user.isAuthPopupVisible ? 'block' : 'none'}}
                      close={() => this.props.setAuthPopupVisibility( {isAuthPopupVisible: false} )} />

                {!isTablet ? <MockDownloadApps /> : ''}
            </div>
        ) : <div />;
    }

    render() {
        return isMobile || isTablet ? this.renderMobile() : this.renderDesktop();
    }
}

const mapStateToProps = state => ({
    user: state.toJS().user,
    pathname: state.toJS().router.location.pathname,
    search: state.toJS().router.location.search,
    hash: state.toJS().router.location.hash,
});

const mapDispatchToProps = dispatch => ({
    getGuestToken: () => dispatch( userActions.getGuestToken() ),
    restoreUserToken: () => dispatch( userActions.restoreUserToken() ),
    logout: () => dispatch( userActions.logout() ),
    setAuthPopupVisibility: ( data ) => dispatch( userActions.setAuthPopupVisibility( data ) ),
    openUserPostCards: () => dispatch( push('/user-postcards') ),
    openAboutService: () => dispatch( push('/about-service') ),
    redirect: ( url ) => dispatch( replace( url ) ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
