import Config from '../config/Config';
let apiUrl = Config.domain + Config.apiType + Config.apiVersion;

function getAuthenticateToken() {
    return window.localStorage.getItem('userToken') || window.token;
}

export default {
    // auth( data ) {
    //     return fetch(apiUrl + '/auth', {
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //         },
    //         credentials: 'include',
    //         method: 'POST',
    //         body: JSON.stringify({
    //             "jsonrpc": "2.0",
    //             "id": "Auth.Login" + parseInt(Math.random() * 100000),
    //             "method": "Auth.Login",
    //             "params": {
    //                 "user": data.userId,
    //                 "password": data.userPassword,
    //             }
    //         }),
    //     })
    //         .then(res => {
    //             if (res.status >= 400) {
    //                 throw new Error("Bad response from server");
    //             }
    //             return res.json();
    //         })
    //         .then(response => {
    //             return response;
    //         })
    //         .catch(err => {
    //             console.error(err);
    //         });
    // },

    registerUser( data ) {
        return fetch(apiUrl + '/users.json', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-App-Version': 'web 0.1.1',
            },
            credentials: 'include',
            method: 'POST',
            body: "phone=%2B7" + data.phone,
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    login( data ) {
        return fetch(apiUrl + '/users/login.json', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'POST',
            body: "phone=%2B7" + data.phone + "&password=" + data.password,
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    logout() {
        return fetch(apiUrl + '/users/logout.json', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'POST',
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getGuestToken() {
        return fetch(apiUrl + '/users/token.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
            },
            credentials: 'include',
            method: 'GET',
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getPreloadedPostCards() {
        return fetch(apiUrl + '/images.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'GET',
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getImageBase64( url ) {//отрефакторить
        let headers = new Headers({
            'X-App-Version': 'web 0.1.1',
            'Authenticate': 'token=' + getAuthenticateToken(),
        });
        let options = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
            cache: 'default'
        };
        let request = new Request(url);

        function arrayBufferToBase64(buffer) {
            let binary = '';
            let bytes = [].slice.call(new Uint8Array(buffer));

            bytes.forEach((b) => binary += String.fromCharCode(b));

            return window.btoa(binary);
        }

        return fetch(request, options).then( async(response) => {
            let base64;
            await response.arrayBuffer().then((buffer) => {
                let base64Flag = 'data:image/jpeg;base64,';
                let imageStr = arrayBufferToBase64(buffer);

                base64 = base64Flag + imageStr;
                return base64;
            });
            return base64;
        });
    },

    getPaperFormats() {
        return fetch(apiUrl + '/postcards/formats.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'GET',
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getPaperTypes() {
        return fetch(apiUrl + '/postcards/types.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'GET',
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getPostCardFonts() {
        return fetch(apiUrl + '/postcards/fonts.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'GET',
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getPostCardFontColors() {
        return fetch(apiUrl + '/postcards/font-colors.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'GET',
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getPostCardTextPreview( data ) {//отрефакторить
        function arrayBufferToBase64(buffer) {
            let binary = '';
            let bytes = [].slice.call(new Uint8Array(buffer));

            bytes.forEach((b) => binary += String.fromCharCode(b));

            return window.btoa(binary);
        }

        return fetch(apiUrl + '/postcards/text.json?font_id=' + data.font + '&font_color='
                                                    + data.fontColor.replace('#', '%23') + '&text=' + data.cardText, {
            headers: {
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'GET',
        })
            .then(async response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                let base64;
                await response.arrayBuffer().then((buffer) => {
                    let base64Flag = 'data:image/jpeg;base64,';
                    let imageStr = arrayBufferToBase64(buffer);

                    base64 = base64Flag + imageStr;
                    return base64;
                });
                return base64;
            })
            .catch(err => {
                console.error(err);
            });
    },

    validateAddress( data ) {
        return fetch(apiUrl + '/postcards/address.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "name": data.name || '',
                "country": data.country || 'Россия',
                "region": data.region || '',
                "city": data.city || '',
                "street": data.street || '',
                // "index": data.index || '',//не ищем по индексу
                "number": data.number || '',
                "building": data.building || '',
                "room": data.room || '',
            }),
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getSavedRecipientInfo( data = {namePart: ''} ) {
        return fetch(apiUrl + '/addresses.json?q=' + data.namePart, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'GET',
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getPostCardPreview( data ) {
        return fetch(apiUrl + '/postcards.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                "image_id": data.creatingPostCardId,
                "font_id": data.font,
                "type_id": data.paperType,
                "format_id": data.paperFormat,
                "font_color": data.fontColor,
                "text": data.cardText,
                "sender": {
                    "id": data.senderAddress.id || null,
                    "name": data.senderAddress.name,
                    "country": "Россия",
                    "region": data.senderAddress.region,
                    "city": data.senderAddress.city,
                    "street": data.senderAddress.street,
                    "index": data.senderAddress.index,
                    "number": data.senderAddress.number,
                    "building": data.senderAddress.building || null,
                    "room": data.senderAddress.room || null,
                },
                "recipient": {
                    "id": data.recipientAddress.id || null,
                    "name": data.recipientAddress.name,
                    "country": "Россия",
                    "region": data.recipientAddress.region,
                    "city": data.recipientAddress.city,
                    "street": data.recipientAddress.street,
                    "index": data.recipientAddress.index,
                    "number": data.recipientAddress.number,
                    "building": data.recipientAddress.building || null,
                    "room": data.recipientAddress.room || null,
                },
            }),
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    uploadPostCard: function( data ) {
        let options = {
            credentials: 'include',
            method: 'POST',
            headers: {
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
        };

        let objToSend = {
            image: data.image,
        };

        options.body = new FormData();
        for ( let key in objToSend ) {
            options.body.append(key, objToSend[key]);
        }

        return fetch( apiUrl + '/images.json', options )
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    patchPostCard( data ) {
        return fetch(apiUrl + '/images/' + data.id + '.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'PATCH',
            body: JSON.stringify({
                angle: data.angle,
            }),
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getPaymentsWays() {
        return fetch(apiUrl + '/payments.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'GET',
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getPaymentURL( data ) {
        return fetch(apiUrl + '/orders.json', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'POST',
            body:   "postcard_id=" + data.postCardId +
                    "&price_id=" + data.priceId +
                    "&type=bank_card",
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getPostCardAfterPaymentActionsData( data ) {
        return fetch(apiUrl + '/payments/' + data.postCardId + '.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'PATCH',
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getUserPostCards( data ) {
        data.page = data.page || 1;
        return fetch(apiUrl + '/postcards.json?page=' + data.page, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': 'web 0.1.1',
                'Authenticate': 'token=' + getAuthenticateToken(),
            },
            credentials: 'include',
            method: 'GET',
        })
            .then(res => {
                if (res.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return res.json();
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    },

    getDirectImageUrl( data ) {
        return apiUrl + '/images/get-image-url/' + data.id + '/' + data.full;
    },
};