import React, { Component } from 'react';

export default class FontChooser extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            isOpen: false,
        };
        this.toggleOpen = this.toggleOpen.bind(this);
    };

    toggleOpen() {
        this.setState({isOpen: !this.state.isOpen});
    };

    render() {
        return (
            <div onClick={this.toggleOpen} className="d-chooser">
                <div className="d-chooser__title">Шрифт</div>
                <div style={{display: this.state.isOpen ? 'block' : 'none'}} className="d-chooser__list">
                    {(this.props.fonts || []).map(( item, index ) => {
                        return  <div key={index} className="d-chooser__list__item"
                                     onClick={() => this.props.setFontByUser( item.id )}>
                                    <div><img className="d-chooser__font-image" src={item.url} alt="" /></div>
                                </div>
                    })}
                </div>
                <div style={{display: !this.state.isOpen ? 'block' : 'none'}}>
                    <img className="d-chooser__font-image"
                         src={this.props.chosenFont ? this.props.chosenFont.url : ''} alt="" />
                </div>
            </div>
        );
    }
}