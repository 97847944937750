import ServerApi from '../api/ServerApi';
import preloadedPostCardsConstants from '../constants/preloadedPostCardsConstants';
import { push } from 'connected-react-router';

export const setPostCards = data => ({
    type: preloadedPostCardsConstants.SET_POST_CARDS,
    data: data,
});

export const loadPostCards = () => {
    return async dispatch => {
        let response = await ServerApi.getPreloadedPostCards();
        if ( response.success ) {
            dispatch( setPostCards( {postCards: response.data} ) );
            dispatch( setImagesBase64( {postCards: response.data} ) );
        } else {
            console.log('loadPostCards response.success false');
        }
    };
};

export const setImagesBase64 = ( data ) => {
    return dispatch => {
        data.postCards.map( ( item, index ) => {
            return ServerApi.getImageBase64( item.url ).then(( base64 ) => {
                dispatch( setImageBase64({
                    base64: base64,
                    id: item.id,
                }) );
            });
        });
    };
};

export const setImageBase64 = data => ({
    type: preloadedPostCardsConstants.SET_IMAGE_BASE64,
    data: data,
});

export const openPostCardCreation = () => {
    return dispatch => {
        dispatch( push('/card-creation/step-one') );
    };
};