import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import BalloonLoader from '../components/BalloonLoader';

class UploadPanel extends Component {
    render() {
        return  <div className="upload-panel" onClick={this.props.onClick}>
                    {this.props.newPostCard.creationData.isPostCardUploading ?
                        <BalloonLoader /> :
                        <div className="upload-panel__content">
                            <div className="upload-panel__title">Загрузите свое изображение</div>
                            <div className="upload-panel__text">или выберите готовый дизайн</div>
                        </div>}
                </div>;
    };
}

const mapStateToProps = state => ({
    newPostCard: state.toJS().newPostCard,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadPanel);
