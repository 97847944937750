import Config from '../config/Config';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import SocSharingButtons from '../components/SocSharingButtons';

let sharingText =
    'Я отправляю настоящие бумажные открытки, используя сервис «Салют! Открытки». Отправь же и ты скорее!';

class SocSharing extends Component {
    render() {
        return (
            <div style={{display: this.props.isVisible ? 'block' : 'none'}}>
                <SocSharingButtons
                    sharingText={sharingText}
                    url={Config.appSharingURL}
                    image={this.props.image}
                    content={this.props.content} />
            </div>
        );
    };
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SocSharing);
