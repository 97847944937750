import React, { Component } from 'react';

export default class AboutService extends Component {
    render() {
        return (
            <div className="content-page wrapper-center about-service">
                <h1>О сервисе</h1>

                <p>
                    Срок доставки открытки после ее передачи в ФГУП «Почта России» до получателя равен сроку доставки
                    простого почтового отправления ФГУП «Почта России» и в зависимости от региона и населенного пункта
                    может составлять от 2 до 11 дней. В отдельных случаях, общий срок доставки открытки до адресата
                    с момента ее оплаты Пользователем, может составлять до 14 рабочих дней.
                </p>

                <p>
                    <a href="https://salut-postcard.ru/agreement">Пользовательское соглашение</a><br />
                    <a href="https://salut-postcard.ru/">Наши приложения</a>
                </p>

                <p>
                    По всем вопросам и предожениям - направляйте письма на почту&nbsp;
                    <a href="mailto:salut.otkrytki@gmail.com">salut.otkrytki@gmail.com</a>
                </p>
            </div>
        );
    }
}