import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import user from './user';
import preloadedPostCards from './preloadedPostCards';
import newPostCard from './newPostCard';
import payments from './payments';
import userPostCards from './userPostCards';

export default ( history ) => combineReducers({
    router: connectRouter( history ),
    user,
    preloadedPostCards,
    newPostCard,
    payments,
    userPostCards,
});