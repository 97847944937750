import React, { Component } from 'react';

export default class ColorChooser extends Component {
    render() {
        return (
            <div className="d-chooser pb19">
                <div className="d-chooser__title">Цвет</div>
                <div className="d-chooser__list-colors clearfix">
                    {(this.props.fontColors || []).map(( item, index ) => {
                        return  <div key={index} className="d-chooser__color"
                                     onClick={() => this.props.setFontColorByUser( item.color )}>
                                    <div className="d-chooser__color__inner"
                                         style={{
                                             backgroundColor: item.color,
                                             boxShadow: item.color === this.props.chosenFontColor
                                                                        ? '0 0 10px 2px rgba(96, 47, 156, 0.8)' : '',
                                         }} />
                                </div>
                    })}
                </div>
            </div>
        );
    }
}