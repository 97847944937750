import newPostCardConstants from '../constants/newPostCardConstants';
import { fromJS, setIn, Map } from "immutable";

const initialState = fromJS({
    creationData: {},
    saveData: {},
});

export default function newPostCard(state = initialState, action) {
    let newState;
    switch (action.type) {
        case newPostCardConstants.SET_PAPER_FORMATS:
            newState = setIn(state, ['creationData', 'paperFormats'], action.data.paperFormats);
            return newState;

        case newPostCardConstants.SET_PAPER_FORMAT_BY_USER:
            newState = setIn(state, ['saveData', 'paperFormat'], action.data.paperFormat);
            return newState;

        case newPostCardConstants.SET_PAPER_TYPES:
            newState = setIn(state, ['creationData', 'paperTypes'], action.data.paperTypes);
            return newState;

        case newPostCardConstants.SET_PAPER_TYPE_BY_USER:
            newState = setIn(state, ['saveData', 'paperType'], action.data.paperType);
            return newState;

        case newPostCardConstants.SET_FONTS:
            newState = setIn(state, ['creationData', 'fonts'], action.data.fonts);
            return newState;

        case newPostCardConstants.SET_FONT_BY_USER:
            newState = setIn(state, ['saveData', 'font'], action.data.font);
            return newState;

        case newPostCardConstants.SET_FONT_COLORS:
            newState = setIn(state, ['creationData', 'fontColors'], action.data.fontColors);
            return newState;

        case newPostCardConstants.SET_FONT_COLOR_BY_USER:
            newState = setIn(state, ['saveData', 'fontColor'], action.data.fontColor);
            return newState;

        case newPostCardConstants.SET_CREATING_POSTCARD_ID:
            newState = setIn(state, ['saveData', 'creatingPostCardId'], action.data.creatingPostCardId);
            return newState;

        case newPostCardConstants.SET_CREATING_POSTCARD_IMAGE:
            newState = setIn(state, ['creationData', 'creatingPostCardImage'], action.data.creatingPostCardImage);
            return newState;

        case newPostCardConstants.SET_CREATING_POSTCARD_IMAGE_ORIGINAL:
            newState = setIn(state, ['creationData', 'creatingPostCardImageOriginal'],
                                                                            action.data.creatingPostCardImageOriginal);
            return newState;

        case newPostCardConstants.SET_CARD_TEXT:
            newState = setIn(state, ['saveData', 'cardText'], action.data.cardText);
            return newState;

        case newPostCardConstants.SET_CARD_TEXT_PREVIEW:
            newState = setIn(state, ['creationData', 'cardTextPreview'], action.data.cardTextPreview);
            return newState;

        case newPostCardConstants.SET_RECIPIENT_ADDRESS_PART:
            newState = setIn(state, ['saveData', 'recipientAddress', action.data.field], action.data.value);
            return newState;

        case newPostCardConstants.SET_SENDER_ADDRESS_PART:
            newState = setIn(state, ['saveData', 'senderAddress', action.data.field], action.data.value);
            return newState;

        case newPostCardConstants.SET_ADDRESSES_LIST:
            newState = setIn(state, ['creationData', 'addressesList'], action.data.addressesList);
            return newState;

        case newPostCardConstants.SET_SAVED_RECIPIENT_INFO:
            newState = setIn(state, ['creationData', 'savedRecipientInfo'], action.data.savedRecipientInfo);
            return newState;

        case newPostCardConstants.SET_SAVED_SENDER_INFO:
            newState = setIn(state, ['creationData', 'savedSenderInfo'], action.data.savedSenderInfo);
            return newState;

        case newPostCardConstants.SET_POSTCARD_PREVIEW:
            newState = setIn(state, ['creationData', 'postCardPreview'], action.data.postCardPreview);
            return newState;

        case newPostCardConstants.CLEAR_POSTCARD_PREVIEW:
            newState = setIn(state, ['creationData', 'postCardPreview'], null);
            return newState;

        case newPostCardConstants.SET_POSTCARD_PREVIEW_ERRORS:
            newState = setIn(state, ['creationData', 'postCardPreviewErrors'], action.data.postCardPreviewErrors);
            return newState;

        case newPostCardConstants.CLEAR_POSTCARD_PREVIEW_ERRORS:
            newState = setIn(state, ['creationData', 'postCardPreviewErrors'], null);
            return newState;

        case newPostCardConstants.SET_FACE_IMAGE_BASE64:
            newState = setIn(state, ['creationData', 'postCardPreview', 'face_url'], action.data.base64);
            return newState;

        case newPostCardConstants.SET_BACK_IMAGE_BASE64:
            newState = setIn(state, ['creationData', 'postCardPreview', 'back_url'], action.data.base64);
            return newState;

        case newPostCardConstants.SET_UPLOADING_POSTCARD:
            newState = setIn(state, ['saveData', 'uploadingPostCard', 'image'], action.data.uploadingPostCard[0]);
            return newState;

        case newPostCardConstants.SET_UPLOADED_POSTCARD:
            newState = setIn(state, ['creationData', 'uploadedPostCard'], action.data.uploadedPostCard);
            return newState;

        case newPostCardConstants.SET_POSTCARD_IS_UPLOADING:
            newState = setIn(state, ['creationData', 'isPostCardUploading'], action.data.isPostCardUploading);
            return newState;

        case newPostCardConstants.SET_POSTCARD_ORIENTATION:
            newState = setIn(state, ['saveData', 'postCardOrientation'], action.data.postCardOrientation);
            return newState;

        default:
            return state;
    }
};
