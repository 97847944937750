import ServerApi from '../api/ServerApi';
import userPostCardsConstants from '../constants/userPostCardsConstants';
import {push} from "connected-react-router";

export const setUserPostCards = data => ({
    type: userPostCardsConstants.SET_USER_POSTCARDS,
    data: data,
});

export const setPaginator = data => ({
    type: userPostCardsConstants.SET_PAGINATOR,
    data: data,
});

export const loadUserPostCards = data => {
    return async dispatch => {
        let response = await ServerApi.getUserPostCards( data );
        if ( response.success ) {
            dispatch( setUserPostCards( {userPostCards: response.data} ) );
            dispatch( setPaginator( {paginator: response.pager} ) );
            dispatch( setFaceImagesOriginal( {userPostCards: response.data} ) )
            dispatch( setFaceImagesDirect( {userPostCards: response.data} ) )
            dispatch( setFaceImagesBase64( {userPostCards: response.data} ) )
            dispatch( setBackImagesBase64( {userPostCards: response.data} ) )
        } else {
            console.log('loadUserPostCards response.success false');
        }
    };
};

export const setFaceImagesOriginal = data => {
    return dispatch => {
        data.userPostCards.map((item, index) => {
            return dispatch(setFaceImageOriginalSingle({
                originalImage: item.face_url,
                id: item.id,
            }));
        });
    };
};

export const setFaceImagesDirect = data => {
    return dispatch => {
        data.userPostCards.map((item, index) => {
            return dispatch(setFaceImageDirectSingle({
                directImage: ServerApi.getDirectImageUrl( {id: item.image.id, full: 1} ),
                id: item.id,
            }));
        });
    };
};

export const setFaceImagesBase64 = ( data ) => {
    return dispatch => {
        data.userPostCards.map( ( item, index ) => {
            return ServerApi.getImageBase64( item.face_url ).then(( base64 ) => {
                dispatch( setFaceImageBase64Single({
                    base64: base64,
                    id: item.id,
                }) );
            });
        });
    };
};

export const setBackImagesBase64 = ( data ) => {
    return dispatch => {
        data.userPostCards.map( ( item, index ) => {
            return ServerApi.getImageBase64( item.back_url ).then(( base64 ) => {
                dispatch( setBackImageBase64Single({
                    base64: base64,
                    id: item.id,
                }) );
            });
        });
    };
};

export const setFaceImageOriginalSingle = data => ({
    type: userPostCardsConstants.SET_FACE_IMAGE_ORIGINAL_SINGLE,
    data: data,
});

export const setFaceImageDirectSingle = data => ({
    type: userPostCardsConstants.SET_FACE_IMAGE_DIRECT_SINGLE,
    data: data,
});

export const setFaceImageBase64Single = data => ({
    type: userPostCardsConstants.SET_FACE_IMAGE_BASE_64_SINGLE,
    data: data,
});

export const setBackImageBase64Single = data => ({
    type: userPostCardsConstants.SET_BACK_IMAGE_BASE_64_SINGLE,
    data: data,
});