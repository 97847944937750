/**
 * Payments constants.
 */

export default {
    SET_IS_YANDEX_FORM_OPEN: 'SET_IS_YANDEX_FORM_OPEN',
    SET_IS_PAYMENT_IFRAME_OPEN: 'SET_IS_PAYMENT_IFRAME_OPEN',
    SET_PAYMENTS_WAYS: 'SET_PAYMENTS_WAYS',
    SET_PAYMENT_TOKEN: 'SET_PAYMENT_TOKEN',
    SET_PAYMENT_URL: 'SET_PAYMENT_URL',
    SET_POST_CARD_AFTER_PAYMENT_ACTIONS_DATA: 'SET_POST_CARD_AFTER_PAYMENT_ACTIONS_DATA',
};