import userConstants from '../constants/userConstants';
import { Map } from 'immutable';
import { fromJS } from "immutable";

const initialState = fromJS({
    token: '',//гостевой, отрефакторить
    userToken: '',//авторизационный, который по телефону и паролю
    userGotPassword: false,
    phone: '',
    isAuthPopupVisible: false,
    errors: null,
});

export default function user(state = initialState, action) {
    let newState;
    switch (action.type) {
        case userConstants.SET_USER_GOT_PASSWORD:
            newState = state.set('userGotPassword', action.data.userGotPassword);
            return newState;

        case userConstants.SET_AUTH_POPUP_VISIBILITY:
            newState = state.set('isAuthPopupVisible', action.data.isAuthPopupVisible);
            return newState;

        case userConstants.SET_USER_TOKEN:
            newState = state.set('userToken', action.data.userToken);//костылище:
            window.localStorage.setItem('userToken', action.data.userToken);// в двух местах храним токен
            return newState;

        case userConstants.RESTORE_USER_TOKEN:
            newState = state.set('userToken', window.localStorage.getItem('userToken') || '');//костылище:
                                                                                        // в двух местах храним токен
            return newState;

        case userConstants.SET_GUEST_TOKEN:
            newState = state.set('token', action.data.token);//костылище:
            window.token = action.data.token;// в двух местах храним токен
            return newState;

        case userConstants.SET_PHONE:
            newState = state.set('phone', action.data.phone);
            return newState;

        case userConstants.SET_AUTH_ERRORS:
            newState = state.set('errors', action.data.errors);
            return newState;

        default:
            return state;
    }
};
