import React, { Component } from 'react';
import _ from 'lodash';

export default class SenderForm extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            name: false,
            country: false,
            region: false,
            city: false,
            street: false,
            index: false,
            number: false,
            building: false,
            room: false,

            nameValue: '',
            countryValue: 'захардкожена',
            regionValue: '',
            cityValue: '',
            streetValue: '',
            indexValue: '',
            numberValue: '',
            buildingValue: '',
            roomValue: '',

            commonErrorText: 'Пожалуйста, проверьте данные в поле',
        };
        this.setDefaultFormValues = this.setDefaultFormValues.bind(this);
        this.setFormValues = this.setFormValues.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.chooseFromDropDown = this.chooseFromDropDown.bind(this);
        this.setIndex = this.setIndex.bind(this);
        this.renderSavedSender = this.renderSavedSender.bind(this);
        this.renderDropdown = this.renderDropdown.bind(this);
        this.renderCommonError = this.renderCommonError.bind(this);
    };

    componentDidMount() {
        this.setDefaultFormValues();
    };

    componentDidUpdate() {
        this.setIndex();
    };

    setDefaultFormValues() {
        if ( this.props.newPostCard.saveData.senderAddress
                && this.props.newPostCard.saveData.senderAddress.name ) {
            this.setState({nameValue: this.props.newPostCard.saveData.senderAddress.name});
        }

        // if ( this.props.newPostCard.saveData.senderAddress
        //         && this.props.newPostCard.saveData.senderAddress.country ) {
        //     this.setState({countryValue: this.props.newPostCard.saveData.senderAddress.country});
        // }

        if ( this.props.newPostCard.saveData.senderAddress
                && this.props.newPostCard.saveData.senderAddress.region ) {
            this.setState({regionValue: this.props.newPostCard.saveData.senderAddress.region});
        }

        if ( this.props.newPostCard.saveData.senderAddress
                && this.props.newPostCard.saveData.senderAddress.city ) {
            this.setState({cityValue: this.props.newPostCard.saveData.senderAddress.city});
        }

        if ( this.props.newPostCard.saveData.senderAddress
                && this.props.newPostCard.saveData.senderAddress.street ) {
            this.setState({streetValue: this.props.newPostCard.saveData.senderAddress.street});
        }

        if ( this.props.newPostCard.saveData.senderAddress
                && this.props.newPostCard.saveData.senderAddress.index ) {
            this.setState({indexValue: this.props.newPostCard.saveData.senderAddress.index});
        }

        if ( this.props.newPostCard.saveData.senderAddress
                && this.props.newPostCard.saveData.senderAddress.number ) {
            this.setState({numberValue: this.props.newPostCard.saveData.senderAddress.number});
        }

        if ( this.props.newPostCard.saveData.senderAddress
                && this.props.newPostCard.saveData.senderAddress.building ) {
            this.setState({buildingValue: this.props.newPostCard.saveData.senderAddress.building});
        }

        if ( this.props.newPostCard.saveData.senderAddress
                && this.props.newPostCard.saveData.senderAddress.room ) {
            this.setState({roomValue: this.props.newPostCard.saveData.senderAddress.room});
        }
    };

    setFormValues( values ) {
        this.setState({nameValue: values.name});
        this.props.onChangeSender('name', values.name);

        // if ( this.props.newPostCard.saveData.senderAddress
        //         && this.props.newPostCard.saveData.senderAddress.country ) {
        //     this.setState({countryValue: this.props.newPostCard.saveData.senderAddress.country});
        // }

        this.setState({regionValue: values.region});
        this.props.onChangeSender('region', values.region);

        this.setState({cityValue: values.city});
        this.props.onChangeSender('city', values.city);

        this.setState({streetValue: values.street});
        this.props.onChangeSender('street', values.street);

        this.setState({indexValue: values.index});
        this.props.onChangeSender('index', values.index);

        this.setState({numberValue: values.number});
        this.props.onChangeSender('number', values.number);

        this.setState({buildingValue: values.building});
        this.props.onChangeSender('building', values.building);

        this.setState({roomValue: values.room});
        this.props.onChangeSender('room', values.room);
    };

    onFocus( fieldName ) {
        this.setState({[fieldName]: true});
    };

    onBlur( fieldName ) {
        setTimeout(() => {
            this.setState({[fieldName]: false});
        }, 250);//скрываем не сразу
    };

    chooseFromDropDown( item, valueName ) {
        this.setState({[valueName + 'Value']: item[valueName]});
        this.props.onChangeSender( valueName, item[valueName] );
    };

    setIndex() {
        if ( this.state.countryValue && this.state.regionValue && this.state.cityValue && this.state.streetValue
                                                                                    && this.state.numberValue ) {
            let index = this.props.newPostCard.creationData.addressesList
                        && this.props.newPostCard.creationData.addressesList[0]
                            ? this.props.newPostCard.creationData.addressesList[0].index : '';
            if ( index && index !== this.state.indexValue ) {
                let address = this.props.newPostCard.creationData.addressesList[0];
                let isSameAddress =
                    (address.region || '').toLowerCase().indexOf( this.state.regionValue.toLowerCase().trim() ) !== -1 &&
                    (address.city || '').toLowerCase().indexOf( this.state.cityValue.toLowerCase().trim() ) !== -1 &&
                    (address.street || '').toLowerCase().indexOf( this.state.streetValue.toLowerCase().trim() ) !== -1 &&
                    (address.number || '').replace( /^\D+/g, '') === this.state.numberValue.replace( /^\D+/g, '') &&
                    (address.building || '').toLowerCase().indexOf( this.state.buildingValue.toLowerCase().trim() ) !== -1 &&
                    (address.room || '').toLowerCase().indexOf( this.state.roomValue.toLowerCase().trim() ) !== -1;
                if ( isSameAddress ) {
                    this.setState({indexValue: index});
                    this.props.onChangeSender('index', index);
                }
            }
        }
    };

    renderSavedSender() {
        let savedSenderInfo = this.props.newPostCard.creationData.savedSenderInfo;
        if ( !savedSenderInfo || !savedSenderInfo.name ) return;
        return (
            <div className="text-input-help__list" style={{display: this.state.name ? 'block' : 'none'}}>
                <div className="text-input-help__list__item"
                     onClick={() => {this.setFormValues( savedSenderInfo );}}>{savedSenderInfo['name']}</div>
            </div>
        );
    };

    renderDropdown( valueName ) {
        if ( !this.props.newPostCard.creationData.addressesList ) return;
        return (
            <div className="text-input-help__list" style={{display: this.state[valueName] ? 'block' : 'none'}}>
                {_.uniqBy( this.props.newPostCard.creationData.addressesList, valueName ).map(( item, index ) => {
                    if ( item[valueName] ) {
                        return <div key={index} className="text-input-help__list__item"
                                    onClick={() => this.chooseFromDropDown( item, valueName )}>{item[valueName]}</div>
                    }
                })}
            </div>
        );
    };

    renderCommonError( field ) {
        return  this.props.newPostCard.creationData.postCardPreviewErrors &&
                this.props.newPostCard.creationData.postCardPreviewErrors.sender &&
                this.props.newPostCard.creationData.postCardPreviewErrors.sender[field]
                    ? <div style={{color: '#f00'}}>{this.state.commonErrorText}</div> : '';
    };

    render() {
        return (
            <div>
                <form>
                    <div className="text-input-help">
                        <label className="text-input-help__label">
                            <span className={"text-input-help__placeholder " +
                                                        ( this.state.name || this.state.nameValue ? 'active' : '' )}>
                                Имя и фамилия отправителя
                            </span>
                            <input type="text"
                                   autoComplete="off"
                                   value={this.state.nameValue}
                                   onFocus={() => this.onFocus( 'name' )}
                                   onBlur={() => this.onBlur( 'name' )}
                                   onChange={( event ) => {
                                       this.setState({nameValue: event.currentTarget.value});
                                       this.props.onChangeSender( 'name', event.currentTarget.value );
                                   }}
                                   name="name" />
                        </label>
                        {this.renderSavedSender()}
                        {this.renderCommonError( 'name' )}
                    </div>

                    <div className="text-input-help">
                        <label className="text-input-help__label">
                            <span className={"text-input-help__placeholder " +
                                                    ( this.state.country || this.state.countryValue ? 'active' : '' )}>
                                Страна отправителя
                            </span>
                            <input type="text"
                                   autoComplete="off"
                                   value="Россия"
                                   onFocus={() => this.onFocus( 'country' )}
                                   onBlur={() => this.onBlur( 'country' )}
                                   disabled
                                   name="country" />
                        </label>
                        {this.renderDropdown( 'country' )}
                        {this.renderCommonError( 'country' )}
                    </div>

                    <div className="text-input-help">
                        <label className="text-input-help__label">
                            <span className={"text-input-help__placeholder " +
                                                    ( this.state.region || this.state.regionValue ? 'active' : '' )}>
                                Регион получателя
                            </span>
                            <input type="text"
                                   autoComplete="off"
                                   value={this.state.regionValue}
                                   onFocus={() => this.onFocus( 'region' )}
                                   onBlur={() => this.onBlur( 'region' )}
                                   onChange={( event ) => {
                                       this.setState({regionValue: event.currentTarget.value});
                                       this.props.onChangeSender( 'region', event.currentTarget.value );
                                   }}
                                   name="region" />
                        </label>
                        {this.renderDropdown( 'region' )}
                        {this.renderCommonError( 'region' )}
                    </div>

                    <div className="text-input-help">
                        <label className="text-input-help__label">
                            <span className={"text-input-help__placeholder " +
                                                        ( this.state.city || this.state.cityValue ? 'active' : '' )}>
                                Город получателя
                            </span>
                            <input type="text"
                                   autoComplete="off"
                                   value={this.state.cityValue}
                                   onFocus={() => this.onFocus( 'city' )}
                                   onBlur={() => this.onBlur( 'city' )}
                                   onChange={( event ) => {
                                       this.setState({cityValue: event.currentTarget.value});
                                       this.props.onChangeSender( 'city', event.currentTarget.value );
                                   }}
                                   name="city" />
                        </label>
                        {this.renderDropdown( 'city' )}
                        {this.renderCommonError( 'city' )}
                    </div>

                    <div className="text-input-help">
                        <label className="text-input-help__label">
                            <span className={"text-input-help__placeholder " +
                                                    ( this.state.street || this.state.streetValue ? 'active' : '' )}>
                                Улица получателя
                            </span>
                            <input type="text"
                                   autoComplete="off"
                                   value={this.state.streetValue}
                                   onFocus={() => this.onFocus( 'street' )}
                                   onBlur={() => this.onBlur( 'street' )}
                                   onChange={( event ) => {
                                       this.setState({streetValue: event.currentTarget.value});
                                       this.props.onChangeSender( 'street', event.currentTarget.value );
                                   }}
                                   name="street" />
                        </label>
                        {this.renderDropdown( 'street' )}
                        {this.renderCommonError( 'street' )}
                    </div>

                    <div className="text-input-help">
                        <label className="text-input-help__label">
                            <span className={"text-input-help__placeholder " +
                                                    ( this.state.number || this.state.numberValue ? 'active' : '' )}>
                                Дом
                            </span>
                            <input type="text"
                                   autoComplete="off"
                                   value={this.state.numberValue}
                                   onFocus={() => this.onFocus( 'number' )}
                                   onBlur={() => this.onBlur( 'number' )}
                                   onChange={( event ) => {
                                       this.setState({numberValue: event.currentTarget.value});
                                       this.props.onChangeSender( 'number', event.currentTarget.value );
                                   }}
                                   name="number" />
                        </label>
                        {this.renderDropdown( 'number' )}
                        {this.renderCommonError( 'number' )}
                    </div>

                    <div className="text-input-help">
                        <label className="text-input-help__label">
                            <span className={"text-input-help__placeholder " +
                                                ( this.state.building || this.state.buildingValue ? 'active' : '' )}>
                                Корпус/строение
                            </span>
                            <input type="text"
                                   autoComplete="off"
                                   value={this.state.buildingValue}
                                   onFocus={() => this.onFocus( 'building' )}
                                   onBlur={() => this.onBlur( 'building' )}
                                   onChange={( event ) => {
                                       this.setState({buildingValue: event.currentTarget.value});
                                       this.props.onChangeSender( 'building', event.currentTarget.value );
                                   }}
                                   name="building" />
                        </label>
                        {this.renderDropdown( 'building' )}
                        {this.renderCommonError( 'building' )}
                    </div>

                    <div className="text-input-help">
                        <label className="text-input-help__label">
                            <span className={"text-input-help__placeholder " +
                                                        ( this.state.room || this.state.roomValue ? 'active' : '' )}>
                                Квартиры/офис
                            </span>
                            <input type="text"
                                   autoComplete="off"
                                   value={this.state.roomValue}
                                   onFocus={() => this.onFocus( 'room' )}
                                   onBlur={() => this.onBlur( 'room' )}
                                   onChange={( event ) => {
                                       this.setState({roomValue: event.currentTarget.value});
                                       this.props.onChangeSender( 'room', event.currentTarget.value );
                                   }}
                                   name="room" />
                        </label>
                        {this.renderDropdown( 'room' )}
                        {this.renderCommonError( 'room' )}
                    </div>

                    <div className="text-input-help">
                        <label className="text-input-help__label">
                            <span className={"text-input-help__placeholder " +
                                                        ( this.state.index || this.state.indexValue ? 'active' : '' )}>
                                Индекс получателя
                            </span>
                            <input type="text"
                                   autoComplete="off"
                                   value={this.state.indexValue}
                                   onFocus={() => this.onFocus( 'index' )}
                                   onBlur={() => this.onBlur( 'index' )}
                                   onChange={( event ) => {
                                       this.setState({indexValue: event.currentTarget.value});
                                       this.props.onChangeSender( 'index', event.currentTarget.value );
                                   }}
                                   name="index" />
                        </label>
                        {this.renderDropdown( 'index' )}
                        {this.renderCommonError( 'index' )}
                    </div>
                </form>
            </div>
        );
    }
}