/**
 * User constants.
 */

export default {
    SET_USER_GOT_PASSWORD: 'SET_USER_GOT_PASSWORD',
    SET_AUTH_POPUP_VISIBILITY: 'SET_AUTH_POPUP_VISIBILITY',
    SET_USER_TOKEN: 'SET_USER_TOKEN',
    RESTORE_USER_TOKEN: 'RESTORE_USER_TOKEN',
    SET_GUEST_TOKEN: 'SET_GUEST_TOKEN',
    SET_PHONE: 'SET_PHONE',
    SET_AUTH_ERRORS: 'SET_AUTH_ERRORS',
};