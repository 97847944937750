import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import AboutServiceComponent from '../components/AboutService';

class AboutService extends Component {
    render() {
        return  <AboutServiceComponent />;
    };
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AboutService);
