import React, { Component } from 'react';

import FontChooser from '../containers/FontChooser';
import ColorChooser from '../containers/ColorChooser';

export default class CardCreationStepOne extends Component {
    renderDesktop() {
        return (
            <div className="card-sign-part clearfix">
                <form>
                    <div className="card-sign-part__format-part">
                        <div className="format-part">
                            <div className="format-part__title">Выберите ориентацию, размер и лицевую сторону</div>

                            <div className="format-part__box">

                                {this.props.newPostCard.saveData.postCardOrientation &&
                                    'angle' in this.props.newPostCard.saveData.postCardOrientation ?
                                <div className={"format-part__box__item " +
                                                    ( !this.props.newPostCard.saveData.postCardOrientation.isVertical
                                                                                    ? "format-part__box__item_h" : '')}>
                                    {this.props.newPostCard.saveData.postCardOrientation.isVertical ?
                                    <div className={"format-part__image format-part__image_" +
                                            (this.props.newPostCard.saveData.postCardOrientation.angle) || 0}
                                         style={{
                                             backgroundImage:
                                             "url(" + this.props.newPostCard.creationData.creatingPostCardImage + ")",
                                         }}>
                                    </div> :
                                    <div className={"format-part__image format-part__image_h format-part__image_" +
                                            (this.props.newPostCard.saveData.postCardOrientation.angle - 90 < 0
                                                ? 270
                                                : this.props.newPostCard.saveData.postCardOrientation.angle - 90)
                                                                                                                || 270}
                                         style={{
                                             backgroundImage:
                                             "url(" + this.props.newPostCard.creationData.creatingPostCardImage + ")",
                                         }}>
                                    </div>}
                                    <div className="format-part__image__rotator format-part__image__rotator_left"
                                        onClick={this.props.rotateLeft} />
                                    <div className="format-part__image__rotator format-part__image__rotator_right"
                                        onClick={this.props.rotateRight} />
                                </div> :
                                <div className="format-part__box__item ">
                                    <div className="format-part__image"
                                         style={{backgroundImage:
                                             "url(" + this.props.newPostCard.creationData.creatingPostCardImage + ")"}}>
                                    </div>
                                </div>}

                                <div className={"format-part__box__item " +
                                                    ( this.props.newPostCard.saveData.postCardOrientation &&
                                                    'angle' in this.props.newPostCard.saveData.postCardOrientation &&
                                                    !this.props.newPostCard.saveData.postCardOrientation.isVertical
                                                                                    ? "format-part__box__item_h" : '')}>
                                    <div className="format-part__description">
                                        <div>
                                            <div className="format-part__type">Формат</div>
                                            <div className="format-part__text">
                                                Выберите формат бумаги из двух возможных,
                                                оба варианта вертикально ориентированы.
                                            </div>
                                        </div>

                                        <div className="mb26">
                                            <label className="d-radio">
                                                {this.props.newPostCard.creationData.paperFormats
                                                    ? this.props.newPostCard.creationData.paperFormats[0].title : ''}
                                                &nbsp;10 х 15 см
                                                <input type="radio" name="paperFormat"
                                                       onChange={( event ) => {
                                                           this.props.setPaperFormatByUser( event.currentTarget.value );
                                                       }}
                                                       value={this.props.newPostCard.creationData.paperFormats
                                                           ? this.props.newPostCard.creationData.paperFormats[0].id : ''}
                                                       defaultChecked={this.props.newPostCard.creationData.paperFormats
                                                       && ( this.props.newPostCard.saveData.paperFormat ==
                                                            this.props.newPostCard.creationData.paperFormats[0].id)} />
                                                <span className="d-radio__checkmark" />
                                            </label>
                                            <label className="d-radio">
                                                {this.props.newPostCard.creationData.paperFormats
                                                    ? this.props.newPostCard.creationData.paperFormats[1].title : ''}
                                                &nbsp;15 х 21 см
                                                <input type="radio" name="paperFormat"
                                                       onChange={( event ) => {
                                                           this.props.setPaperFormatByUser( event.currentTarget.value );
                                                       }}
                                                       value={this.props.newPostCard.creationData.paperFormats
                                                           ? this.props.newPostCard.creationData.paperFormats[1].id : ''}
                                                       defaultChecked={this.props.newPostCard.creationData.paperFormats
                                                       && ( this.props.newPostCard.saveData.paperFormat ==
                                                            this.props.newPostCard.creationData.paperFormats[1].id)} />
                                                <span className="d-radio__checkmark" />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="format-part__description">
                                        <div className="format-part__type">Лицевая сторона</div>
                                        <div className="format-part__text">
                                            Глянцевая бумага отличается
                                            характерным зеркальным эффектом
                                        </div>

                                        <div className="mb26">
                                        {(this.props.newPostCard.creationData.paperTypes || []).map(( item, index ) => {
                                            return (
                                                <label key={index} className="d-radio">
                                                    {item.title}
                                                    <input type="radio" name="paperType" value={item.id}
                                                           onChange={( event ) => {
                                                               this.props.setPaperTypeByUser( event.currentTarget.value );
                                                           }}
                                                           checked={this.props.newPostCard.creationData.paperTypes
                                                                && ( this.props.newPostCard.saveData.paperType ==
                                                            this.props.newPostCard.creationData.paperTypes[index].id)} />
                                                    <span className="d-radio__checkmark" />
                                                </label>
                                            );
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-sign-part__text-part">
                        <div className="text-part">
                            <div className="text-part__title">Текст на открытке</div>

                            <FontChooser onChange={( font ) => {
                                this.props.onChangeCardFontSettings( {font} );
                            }} />

                            <ColorChooser onChange={( color ) => {
                                this.props.onChangeCardFontSettings( {color} );
                            }} />

                            <div className="d-chooser pb19">
                                <label htmlFor="textPreviewInput" className="d-chooser__title">
                                    Введите текст на открытке
                                </label>
                                <div className="d-chooser__input-w">
                                    <textarea className="d-chooser__input-text" placeholder="Текст на открытке"
                                       id="textPreviewInput" maxLength={600}
                                       value={this.props.newPostCard.saveData.cardText}
                                       onChange={(event) => {
                                           this.props.onChangeCardFontSettings( {text: event.currentTarget.value} );
                                       }} />
                                </div>
                                {'isCardTextRowsAmountValid' in this.props.firstStepFieldsValidateParams &&
                                    !this.props.firstStepFieldsValidateParams.isCardTextRowsAmountValid ?
                                        <div className="d-error-message">Поле содержит слишком много строк</div> :
                                        <div />}
                            </div>

                            {this.props.newPostCard.creationData.cardTextPreview
                                                                        && this.props.newPostCard.saveData.cardText
                                ?   <div className="d-chooser pb0 mb80">
                                        <div className="d-chooser__title">Текст на открытке</div>
                                        <div className="d-chooser__text-preview">
                                            <img src={this.props.newPostCard.creationData.cardTextPreview} alt="" />
                                        </div>
                                    </div> : <div />}

                            <div className="wrapper-right">
                                {this.props.isValidFirstStepForm
                                    ?   <a href="/" className="button"
                                           onClick={( event ) => {event.preventDefault();this.props.openSecondStep();}}>
                                            Далее
                                        </a> : <span />}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    renderMobile() {
        return (
            <div className="card-sign-part clearfix">
                <form>
                    <div className="card-sign-part__format-part">
                        <div className="format-part">
                            <div className="format-part__title">Выберите ориентацию,<br />размер и лицевую сторону</div>

                            <div className="format-part__box">

                                {this.props.newPostCard.saveData.postCardOrientation &&
                                    'angle' in this.props.newPostCard.saveData.postCardOrientation ?
                                <div className={"format-part__box__item " +
                                                    ( !this.props.newPostCard.saveData.postCardOrientation.isVertical
                                                                                    ? "format-part__box__item_h" : '')}>
                                    {this.props.newPostCard.saveData.postCardOrientation.isVertical ?
                                    <div className={"format-part__image format-part__image_" +
                                            (this.props.newPostCard.saveData.postCardOrientation.angle) || 0}
                                         style={{
                                             backgroundImage:
                                             "url(" + this.props.newPostCard.creationData.creatingPostCardImage + ")",
                                         }}>
                                    </div> :
                                    <div className={"format-part__image format-part__image_h format-part__image_" +
                                            (this.props.newPostCard.saveData.postCardOrientation.angle - 90 < 0
                                                ? 270
                                                : this.props.newPostCard.saveData.postCardOrientation.angle - 90)
                                                                                                                || 270}
                                         style={{
                                             backgroundImage:
                                             "url(" + this.props.newPostCard.creationData.creatingPostCardImage + ")",
                                         }}>
                                    </div>}
                                    <div className="format-part__image__rotator format-part__image__rotator_left"
                                        onClick={this.props.rotateLeft} />
                                    <div className="format-part__image__rotator format-part__image__rotator_right"
                                        onClick={this.props.rotateRight} />
                                </div> :
                                <div className="format-part__box__item ">
                                    <div className="format-part__image"
                                         style={{backgroundImage:
                                             "url(" + this.props.newPostCard.creationData.creatingPostCardImage + ")"}}>
                                    </div>
                                </div>}

                                <div className={"format-part__box__item " +
                                                    ( this.props.newPostCard.saveData.postCardOrientation &&
                                                    'angle' in this.props.newPostCard.saveData.postCardOrientation &&
                                                    !this.props.newPostCard.saveData.postCardOrientation.isVertical
                                                                                    ? "format-part__box__item_h" : '')}>
                                    <div className="format-part__description">
                                        <div>
                                            <div className="format-part__type">Формат</div>
                                            <div className="format-part__text">
                                                Выберите формат бумаги из двух возможных,
                                                оба варианта вертикально ориентированы.
                                            </div>
                                        </div>

                                        <div className="mb26">
                                            <label className="d-radio">
                                                {this.props.newPostCard.creationData.paperFormats
                                                    ? this.props.newPostCard.creationData.paperFormats[0].title : ''}
                                                &nbsp;10 х 15 см
                                                <input type="radio" name="paperFormat"
                                                       onChange={( event ) => {
                                                           this.props.setPaperFormatByUser( event.currentTarget.value );
                                                       }}
                                                       value={this.props.newPostCard.creationData.paperFormats
                                                           ? this.props.newPostCard.creationData.paperFormats[0].id : ''}
                                                       defaultChecked={this.props.newPostCard.creationData.paperFormats
                                                       && ( this.props.newPostCard.saveData.paperFormat ==
                                                            this.props.newPostCard.creationData.paperFormats[0].id)} />
                                                <span className="d-radio__checkmark" />
                                            </label>
                                            <label className="d-radio">
                                                {this.props.newPostCard.creationData.paperFormats
                                                    ? this.props.newPostCard.creationData.paperFormats[1].title : ''}
                                                &nbsp;15 х 21 см
                                                <input type="radio" name="paperFormat"
                                                       onChange={( event ) => {
                                                           this.props.setPaperFormatByUser( event.currentTarget.value );
                                                       }}
                                                       value={this.props.newPostCard.creationData.paperFormats
                                                           ? this.props.newPostCard.creationData.paperFormats[1].id : ''}
                                                       defaultChecked={this.props.newPostCard.creationData.paperFormats
                                                       && ( this.props.newPostCard.saveData.paperFormat ==
                                                            this.props.newPostCard.creationData.paperFormats[1].id)} />
                                                <span className="d-radio__checkmark" />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="format-part__description">
                                        <div className="format-part__type">Лицевая сторона</div>
                                        <div className="format-part__text">
                                            Глянцевая бумага отличается
                                            характерным зеркальным эффектом
                                        </div>

                                        <div className="mb26">
                                        {(this.props.newPostCard.creationData.paperTypes || []).map(( item, index ) => {
                                            return (
                                                <label key={index} className="d-radio">
                                                    {item.title}
                                                    <input type="radio" name="paperType" value={item.id}
                                                           onChange={( event ) => {
                                                               this.props.setPaperTypeByUser( event.currentTarget.value );
                                                           }}
                                                           checked={this.props.newPostCard.creationData.paperTypes
                                                                && ( this.props.newPostCard.saveData.paperType ==
                                                            this.props.newPostCard.creationData.paperTypes[index].id)} />
                                                    <span className="d-radio__checkmark" />
                                                </label>
                                            );
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-sign-part__text-part">
                        <div className="text-part">
                            <div className="text-part__title">Текст на открытке</div>

                            <FontChooser onChange={( font ) => {
                                this.props.onChangeCardFontSettings( {font} );
                            }} />

                            <ColorChooser onChange={( color ) => {
                                this.props.onChangeCardFontSettings( {color} );
                            }} />

                            <div className="d-chooser pb19">
                                <label htmlFor="textPreviewInput" className="d-chooser__title">
                                    Введите текст на открытке
                                </label>
                                <div className="d-chooser__input-w">
                                    <textarea className="d-chooser__input-text" placeholder="Текст на открытке"
                                       id="textPreviewInput" maxLength={600}
                                       value={this.props.newPostCard.saveData.cardText}
                                       onChange={(event) => {
                                           this.props.onChangeCardFontSettings( {text: event.currentTarget.value} );
                                       }} />
                                </div>
                                {'isCardTextRowsAmountValid' in this.props.firstStepFieldsValidateParams &&
                                    !this.props.firstStepFieldsValidateParams.isCardTextRowsAmountValid ?
                                        <div className="d-error-message">Поле содержит слишком много строк</div> :
                                        <div />}
                            </div>

                            {this.props.newPostCard.creationData.cardTextPreview
                                                                        && this.props.newPostCard.saveData.cardText
                                ?   <div className="d-chooser pb0 mb20">
                                        <div className="d-chooser__title">Пример текста</div>
                                        <div className="d-chooser__text-preview">
                                            <img src={this.props.newPostCard.creationData.cardTextPreview} alt="" />
                                        </div>
                                    </div> : <div />}

                            {this.props.isValidFirstStepForm
                                ?   <a href="/" className="button width-full"
                                       onClick={( event ) => {event.preventDefault();this.props.openSecondStep();}}>
                                        Далее
                                    </a> : <span />}
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    render() {
        return window.isMobile || window.isTablet ? this.renderMobile() : this.renderDesktop();
    }
}