import React, { Component } from 'react';

import iconStatus0 from '../images/statuses/STATUS_CREATED-0.png'
import iconStatus1 from '../images/statuses/STATUS_PAYED-1.png'
import iconStatus1A from '../images/statuses/STATUS_PAYED-1-A.png'
import iconStatus1D from '../images/statuses/STATUS_PAYED-1-D.png'
import iconStatus2 from '../images/statuses/STATUS_NOT_PAYED-2.png'
import iconStatus3 from '../images/statuses/STATUS_SHIPPING-3.png'
import iconStatus4 from '../images/statuses/STATUS_SUCCESS_SHIPPING-4.png'
//5й не выводится и не приходит с сервера
import iconStatus6 from '../images/statuses/STATUS_WAITING_PAY-6.png'

let dependingStatusInfo = {
    0: {
        icon: iconStatus0,
        showStateName: false,
    },
    1: {
        icon: iconStatus1,
        iconApproved: iconStatus1A,
        iconDeclined: iconStatus1D,
        showStateName: true,
    },
    2: {
        icon: iconStatus2,
        showStateName: false,
    },
    3: {
        icon: iconStatus3,
        iconApproved: iconStatus3,
        showStateName: false,
    },
    4: {
        icon: iconStatus4,
        iconApproved: iconStatus4,
        showStateName: false,
    },
    6: {
        icon: iconStatus6,
        showStateName: false,
    },
};

export default class UserPostCards extends Component {
    chooseStatusIcon( item ) {
        let iconAddress =
            item.is_approved === null ? 'icon' : item.is_approved === false ? 'iconDeclined' : 'iconApproved';
        return dependingStatusInfo[item.status][iconAddress];
    };

    isStateNameVisible( item ) {
        return item.state_name ? dependingStatusInfo[item.status].showStateName : false;
    };

    formatDate( date ) {
        date = new Date( date );
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let month = date.getMonth() < 10 ? '0' + ( date.getMonth() + 1 ) : date.getMonth() + 1;
        let year = date.getFullYear();
        return day + '.' + month + '.' + year;
    };

    render() {
        let userPostCards = this.props.userPostCards;
        return (
            <div className="user-cards clearfix">
                {userPostCards.map(( item, index ) => {
                    return (
                        <a href="#" className="user-card" key={index}
                           onClick={( event ) => {
                               event.preventDefault();
                               this.props.openSinglePostCard( item.id )
                        }}>
                            <div className={'user-card__face' +
                                                        (item.image.is_vertical === false ? ' user-card__face_h' : '')}
                                 style={{backgroundImage: "url(" + item.face_url + ")"}} />
                            <div className="user-card__info">
                                {item.send_date ?
                                    <div className="user-card__date">{this.formatDate( item.send_date )}</div> : ''}
                                {item.statusName
                                    ?   <div  className="user-card__approving-status"
                                              style={{backgroundImage: "url(" + this.chooseStatusIcon( item ) + ")"}}>
                                            {item.statusName}
                                        </div> : ''}
                                <div className="user-card__status">
                                    {this.isStateNameVisible( item ) ? item.state_name : ''}
                                </div>
                                <div className="user-card__recipient">
                                    <div className="user-card__recipient__title">Кому</div>
                                    <div className="user-card__recipient__name">{item.recipient_name}</div>
                                </div>
                            </div>
                        </a>
                    );
                })}
            </div>
        );
    }
}