import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as preloadedPostCardsActions from '../actions/preloadedPostCardsActions';
import * as newPostCardActions from '../actions/newPostCardActions';

import CardCatalogueComponent from '../components/CardCatalogue';

class CardCatalogue extends Component {
    componentDidMount() {
        this.props.loadPostCards();
    };

    splitPostCardsWithCategories( cardsAr ) {
        let postCards = [];
        let objResult = {};
        let categoriesNames = [];
        cardsAr.forEach(( item, index ) => {
            if ( !objResult[item.category_id] ) {
                objResult[item.category_id] = [];
                categoriesNames.push( item.image_category.title );
                objResult[item.category_id].push( item );
            } else {
                objResult[item.category_id].push( item );
            }
        });
        for ( let key in objResult ) {
            postCards.push( objResult[key] );
        }
        return {postCards, categoriesNames};
    };

    render() {
        return  <div style={this.props.style}>
                    <CardCatalogueComponent
                        preloadedPostCards={this.splitPostCardsWithCategories( this.props.preloadedPostCards.items )}
                        openPostCardCreation={this.props.openPostCardCreation}/>
                </div>;
    };
}

const mapStateToProps = state => ({
    preloadedPostCards: state.toJS().preloadedPostCards,
});

const mapDispatchToProps = dispatch => ({
    loadPostCards: () => dispatch( preloadedPostCardsActions.loadPostCards() ),
    openPostCardCreation: ( creatingPostCardId, creatingPostCardImage, creatingPostCardImageOriginal ) => {
        dispatch( preloadedPostCardsActions.openPostCardCreation() );
        dispatch( newPostCardActions.setPostCardOrientation( {} ) );//чистим, т к по наличию данных об ориентации дальше
                                                                    // определяем как отображать картинку:
                                                                    // предзагруженную крутить нельзя, а загруженную
                                                                    // юзером - можно
        dispatch( newPostCardActions.setCreatingPostcardId( {creatingPostCardId: creatingPostCardId} ) );
        dispatch( newPostCardActions.setCreatingPostcardImage( {creatingPostCardImage: creatingPostCardImage} ) );
        dispatch( newPostCardActions.setCreatingPostcardImageOriginal( {creatingPostCardImageOriginal} ) );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardCatalogue);
