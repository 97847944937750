import userConstants from '../constants/userConstants';
import ServerApi from "../api/ServerApi";

export const registerUser = data => {
    return async dispatch => {
        dispatch( setAuthErrors( {errors: null} ) );
        let response = await ServerApi.registerUser( data );
        if ( response.success ) {
            dispatch( setUserGotPassword( {userGotPassword: true} ) );
        } else {
            console.log('registerUser response.success false');
        }
    };
};

export const setUserGotPassword = data => ({
    type: userConstants.SET_USER_GOT_PASSWORD,
    data: data,
});

export const login = data => {
    return async dispatch => {
        dispatch( setAuthErrors( {errors: null} ) );
        let response = await ServerApi.login( data );
        if ( response.success ) {
            dispatch( setUserToken( {userToken: response.data.token} ) );
            dispatch( setAuthPopupVisibility( {isAuthPopupVisible: false} ) );
        } else {
            dispatch( setAuthErrors( {errors: {error: 'unknown'}} ) );//с сервера вообще null приходит
            console.log('login response.success false');
        }
    };
};

export const setAuthPopupVisibility = data => ({
    type: userConstants.SET_AUTH_POPUP_VISIBILITY,
    data: data,
});

export const setUserToken = data => ({
    type: userConstants.SET_USER_TOKEN,
    data: data,
});

export const restoreUserToken = data => ({
    type: userConstants.RESTORE_USER_TOKEN,
    data: data,
});

export const setGuestToken = data => ({
    type: userConstants.SET_GUEST_TOKEN,
    data: data,
});

export const getGuestToken = () => {
    return async dispatch => {
        let response = await ServerApi.getGuestToken();
        if ( response.success ) {
            dispatch( setGuestToken( {token: response.data.token} ) );
        } else {
            console.log('getGuestToken response.success false');
        }
    };
};

export const purgePhoneNumber = ( number ) => {
    return number.replace(/[^\d]/g, '');
};

export const setPhone = data => ({
    type: userConstants.SET_PHONE,
    data: data,
});

export const setPurePhone = ( data ) => {
    return async dispatch => {
        dispatch( setPhone( {phone: purgePhoneNumber( data.phone )} ) );
    };
};

export const setAuthErrors = data => ({
    type: userConstants.SET_AUTH_ERRORS,
    data: data,
});

export const logout = () => {
    return async dispatch => {
        let response = await ServerApi.logout();
        if ( response.success ) {
            dispatch( setGuestToken( {token: ''} ) );
            dispatch( setUserToken( {userToken: ''} ) );
            dispatch( setPhone( {phone: ''} ) );
            dispatch( setUserGotPassword( {userGotPassword: false} ) );
            dispatch( getGuestToken() );
            window.location = '/';
        } else {
            console.log('logout response.success false');
        }
    };
};