export default {
    domain: 'https://promo.salut-postcard.ru',
    apiType: '/api',
    apiVersion: '/v1.0',
    shopId: '593545',
    appSharingURL: 'https://salut-postcard.ru/',
    mobileAppsUrl: 'https://promo.salut-postcard.ru/',
    redirectConfig: [
        {
            url: '/user-postcards/single-postcard',
            targetUrl: '/user-postcards',
        },
        {
            url: '/card-creation/step-one',
            targetUrl: '/',
        },
        {
            url: '/card-creation/step-two',
            targetUrl: '/',
        },
    ],
};