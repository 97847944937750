import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';

let sendPasswordTimeout;

export default class Auth extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            isPhoneInFocus: false,
            isPasswordInFocus: false,
            password: '',
            isAgreementChecked: false,
            isSendBtnDisabled: false,
        };
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onPhoneChange = this.onPhoneChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onChangeAgreement = this.onChangeAgreement.bind(this);
        this.disableSendPasswordBtn = this.disableSendPasswordBtn.bind(this);
        this.enableSendPasswordBtn = this.enableSendPasswordBtn.bind(this);
    };

    onFocus( focusOption ) {
        this.setState({[focusOption]: true});
    };

    onBlur( focusOption ) {
        this.setState({[focusOption]: false});
    };

    onPhoneChange( value ) {
        this.props.setPurePhone( value );
    };

    onPasswordChange( value ) {
        this.setState({password: value});
    };

    onChangeAgreement() {
        this.setState({isAgreementChecked: !this.state.isAgreementChecked});
    };

    disableSendPasswordBtn() {
        this.setState({isSendBtnDisabled: true});
        sendPasswordTimeout = setTimeout(() => {
            this.enableSendPasswordBtn();
        }, 30000);
    };

    enableSendPasswordBtn() {
        this.setState({isSendBtnDisabled: false});
    };

    renderDesktop() {
        return (
            <div className="auth-popup-w">
                <div className="auth-popup">
                    <div className="auth-popup__closer" onClick={this.props.close} />
                    <div className="auth-popup__inner">
                        <form>
                            <div className="auth-popup__title">Вход</div>

                            <div className="text-input-help">
                                <label className="text-input-help__label">
                                    <div className={"text-input-help__placeholder " +
                                        ( this.props.user.phone.length || this.state.isPhoneInFocus ? 'active' : '' )}>
                                        Введите номер телефона
                                    </div>
                                    <span className="text-input-help__prefix">+7</span>
                                    <MaskedInput
                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                           type="text" name="phone" required={true} value={this.props.user.phone}
                                           onFocus={() => this.onFocus( 'isPhoneInFocus' )}
                                           onBlur={() => this.onBlur( 'isPhoneInFocus' )}
                                           onChange={( event ) => this.onPhoneChange( event.currentTarget.value )} />
                                </label>
                                <input type="submit" value="Отправить пароль" className="auth-popup__send"
                                    style={{display: this.props.user.phone.length === 10 ? 'block' : 'none'}}
                                    disabled={this.state.isSendBtnDisabled}
                                    onClick={( event ) => {
                                        event.preventDefault();
                                        if ( this.props.user.phone ) {
                                            this.disableSendPasswordBtn();
                                            this.props.registerUser( this.props.user.phone );
                                        }
                                    }} />
                            </div>

                            <div style={{display: this.props.user.userGotPassword ? 'block' : 'none'}}>
                                <div className="text-input-help mb18">
                                    <label className="text-input-help__label">
                                        <div className={"text-input-help__placeholder " +
                                            ( this.state.password || this.state.isPasswordInFocus ? 'active' : '' )}>
                                            Введите код из смс
                                        </div>
                                        <input type="password" name="password"
                                               onFocus={() => this.onFocus( 'isPasswordInFocus' )}
                                               onBlur={() => this.onBlur( 'isPasswordInFocus' )}
                                               onChange={( event ) => {
                                                   this.onPasswordChange( event.currentTarget.value );
                                               }} />
                                    </label>
                                </div>

                                {this.props.user.errors ?
                                    <div className="d-error-message">Пожалуйста, проверьте данные в полях</div> : ''}

                                <label className="d-checkbox">
                                    Вы подтверждаете свое согласие
                                    с действующим&nbsp;
                                    <a href="https://salut-postcard.ru/agreement" target="_blank" className="bbottom-r">
                                        пользовательским соглашением
                                    </a>
                                    <input type="checkbox" value={this.state.isAgreementChecked}
                                           onChange={this.onChangeAgreement} />
                                    <span className="d-checkbox__checkmark" />
                                </label>

                                <div className="wrapper-center">
                                    <input type="submit" value="Войти" className="button"
                                        disabled={!this.state.isAgreementChecked}
                                        onClick={( event ) => {
                                            event.preventDefault();
                                            this.props.login( this.props.user.phone, this.state.password );
                                        }} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    renderMobile() {
        return (
            <div className="auth-popup-w">
                <div className="auth-popup">
                    <div className="auth-popup__closer" onClick={this.props.close} />
                    <div className="auth-popup__inner">
                        <form>
                            <div className="auth-popup__title">Вход</div>

                            <div className="text-input-help">
                                <label className="text-input-help__label">
                                    <div className={"text-input-help__placeholder " +
                                        ( this.props.user.phone.length || this.state.isPhoneInFocus ? 'active' : '' )}>
                                        Введите номер телефона
                                    </div>
                                    <span className="text-input-help__prefix">+7</span>
                                    <MaskedInput
                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                           type="text" name="phone" required={true} value={this.props.user.phone}
                                           onFocus={() => this.onFocus( 'isPhoneInFocus' )}
                                           onBlur={() => this.onBlur( 'isPhoneInFocus' )}
                                           onChange={( event ) => this.onPhoneChange( event.currentTarget.value )} />
                                </label>
                                <input type="submit" value="Отправить пароль" className="auth-popup__send"
                                    style={{display: this.props.user.phone.length === 10 ? 'block' : 'none'}}
                                    disabled={this.state.isSendBtnDisabled}
                                    onClick={( event ) => {
                                        event.preventDefault();
                                        if ( this.props.user.phone ) {
                                            this.disableSendPasswordBtn();
                                            this.props.registerUser( this.props.user.phone );
                                        }
                                    }} />
                            </div>

                            <div style={{display: this.props.user.userGotPassword ? 'block' : 'none'}}>
                                <div className="text-input-help mb18">
                                    <label className="text-input-help__label">
                                        <div className={"text-input-help__placeholder " +
                                            ( this.state.password || this.state.isPasswordInFocus ? 'active' : '' )}>
                                            Введите код из смс
                                        </div>
                                        <input type="password" name="password"
                                               onFocus={() => this.onFocus( 'isPasswordInFocus' )}
                                               onBlur={() => this.onBlur( 'isPasswordInFocus' )}
                                               onChange={( event ) => {
                                                   this.onPasswordChange( event.currentTarget.value );
                                               }} />
                                    </label>
                                </div>

                                {this.props.user.errors ?
                                    <div className="d-error-message">Пожалуйста, проверьте данные в полях</div> : ''}

                                <label className="d-checkbox">
                                    Вы подтверждаете свое согласие
                                    с действующим&nbsp;
                                    <a href="https://salut-postcard.ru/agreement" target="_blank" className="bbottom-r">
                                        пользовательским соглашением
                                    </a>
                                    <input type="checkbox" value={this.state.isAgreementChecked}
                                           onChange={this.onChangeAgreement} />
                                    <span className="d-checkbox__checkmark" />
                                </label>

                                <div className="wrapper-center">
                                    <input type="submit" value="Войти" className="button width-full"
                                        disabled={!this.state.isAgreementChecked}
                                        onClick={( event ) => {
                                            event.preventDefault();
                                            this.props.login( this.props.user.phone, this.state.password );
                                        }} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return window.isMobile || window.isTablet ? this.renderMobile() : this.renderDesktop();
    }
}