import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as newPostCardActions from '../actions/newPostCardActions';

import ImageUploader from 'react-images-upload';

class CardUploader extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            pictureFilesLocal: [],
        };
        this.onDrop = this.onDrop.bind(this);
        this.onDropSuccess = this.onDropSuccess.bind(this);
        this.onDropError = this.onDropError.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
	}

	onDrop( pictureFiles, pictureDataURLs ) {
        this.state.pictureFilesLocal.length === pictureFiles.length
            ? this.onDropError( pictureFiles, pictureDataURLs ) : this.onDropSuccess( pictureFiles, pictureDataURLs );
	};

	onDropSuccess( pictureFiles, pictureDataURLs ) {
        this.setState({pictureFilesLocal: pictureFiles});
        pictureFiles = [pictureFiles[pictureFiles.length - 1]];
        this.props.setUploadingPostCard( pictureFiles );
        setTimeout(() => {
            this.props.uploadPostCard( {image: this.props.newPostCard.saveData.uploadingPostCard.image} );
        }, 0);
    };

    onDropError( pictureFiles, pictureDataURLs ) {
        this.setState({pictureFilesLocal: pictureFiles});
    };

    clearErrors() {
        let parent = document.querySelector('.upload-btn .fileContainer .errorsContainer');
        let child = document.querySelector('.upload-btn .fileContainer .errorsContainer .errorMessage');
        if ( child ) {
            parent.removeChild( child );
        }
    };

    render() {
        return  <form style={this.props.style} className={this.props.className}>
                    <div className="upload-btn js-upload-btn" onClick={this.clearErrors}>
                        <ImageUploader
                            withIcon={true}
                            fileSizeError="Размер файла слишком большой"
                            fileTypeError="Расширение файла не поддерживается"
                            onChange={this.onDrop}
                            imgExtension={['.jpg', '.png', '.jpeg']}
                            maxFileSize={8000000} />
                    </div>
                </form>;
    };
}

const mapStateToProps = state => ({
    newPostCard: state.toJS().newPostCard,
});

const mapDispatchToProps = dispatch => ({
    setUploadingPostCard: ( uploadingPostCard ) => {
        dispatch( newPostCardActions.setUploadingPostCard( {uploadingPostCard} ) );
    },
    uploadPostCard: ( data ) => {
        dispatch( newPostCardActions.uploadPostCard( {image: data.image} ) );
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardUploader);
