/**
 * User post card constants.
 */

export default {
    SET_USER_POSTCARDS: 'SET_USER_POSTCARDS',
    SET_PAGINATOR: 'SET_PAGINATOR',
    SET_FACE_IMAGE_ORIGINAL_SINGLE: 'SET_FACE_IMAGE_ORIGINAL_SINGLE',
    SET_FACE_IMAGE_DIRECT_SINGLE: 'SET_FACE_IMAGE_DIRECT_SINGLE',
    SET_FACE_IMAGE_BASE_64_SINGLE: 'SET_FACE_IMAGE_BASE_64_SINGLE',
    SET_BACK_IMAGE_BASE_64_SINGLE: 'SET_BACK_IMAGE_BASE_64_SINGLE',
};