import React, { Component } from 'react';

export default class Paginator extends Component {
    onPageClick( event, index ) {
        event.preventDefault();
        this.props.onPageClick( index );
    };

    getPagesAr() {
        let ar = [];
        if ( this.props.pagesLength ) {
            for ( let i = 1; i <= this.props.pagesLength; i++ ) {
                ar.push( i );
            }
        }
        return ar;
    };

    render() {
        return (
            <div className="paginator">
                {this.getPagesAr().map(( item, index ) => {
                    return <a onClick={( event ) => this.onPageClick( event, item )}
                              className={'paginator__link ' + ( item === this.props.currentPage ? 'active' : '' )}
                              href="#" key={index}>{item}</a>
                })}
            </div>
        );
    }
}