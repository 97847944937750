import React, { Component } from 'react';

import PaymentButton from '../containers/PaymentButton';

import RecipientForm from './RecipientForm';
import SenderForm from './SenderForm';

export default class CardCreationStepTwo extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            isPopupOpen: false,
            popupShownUrl: '',
            isFaceImageChosen: false,
        };
        this.showPopup = this.showPopup.bind(this);
        this.hidePopup = this.hidePopup.bind(this);
        this.onClickFaceImage = this.onClickFaceImage.bind(this);
        this.onClickBackImage = this.onClickBackImage.bind(this);
        this.renderPopup = this.renderPopup.bind(this);
    };

    componentDidMount() {
        this.props.onComponentDidMount();
        window.scrollTo(0, 0);
    };

    showPopup() {
        this.setState({isPopupOpen: true});
    };

    hidePopup() {
        this.setState({isPopupOpen: false});
    };

    onClickFaceImage( url ) {
        this.setState({popupShownUrl: url});
        this.setState({isFaceImageChosen: true});
        this.showPopup();
    };

    onClickBackImage( url ) {
        this.setState({popupShownUrl: url});
        this.setState({isFaceImageChosen: false});
        this.showPopup();
    };

    renderPopup() {
        let isHorizontal =  this.props.newPostCard.creationData.postCardPreview &&
                                                                    //null если открытка предзагружена
                            this.props.newPostCard.creationData.postCardPreview.image.angle !== null &&
                            this.props.newPostCard.creationData.postCardPreview.image.is_vertical !== null &&
                            !this.props.newPostCard.creationData.postCardPreview.image.is_vertical &&
                            this.state.isFaceImageChosen;
        return (
            <div className={"image-card-popup " + ( isHorizontal ? 'image-card-popup_h' : '' )}>
                <div className="image-card-popup__w">
                    <div className="image-card-popup__img-outer">
                        <div className="image-card-popup__closer" onClick={this.hidePopup} />
                        <img src={this.state.popupShownUrl} alt="" />
                    </div>
                </div>
            </div>
        );
    };

    renderDesktop() {
        return (
            <div className="card-fields-part clearfix">
                <div className="card-fields-part__fields">
                    <div className="card-fields-part__fields__inner">
                        <div className="card-fields-part__fields__recipient">
                            <div className="card-fields-part__title">Кому</div>
                            <RecipientForm
                                newPostCard={this.props.newPostCard}
                                onChangeRecipient={this.props.onChangeRecipient} />
                        </div>
                        <div className="card-fields-part__fields__sender">
                            <div className="card-fields-part__title">От кого</div>
                            <SenderForm
                                newPostCard={this.props.newPostCard}
                                onChangeSender={this.props.onChangeSender} />
                        </div>
                    </div>
                </div>

                <div className="card-fields-part__preview">
                    <div>
                        {this.props.newPostCard.creationData.postCardPreview ?
                        <div className={"card-preview " +
                                                                                    //null если открытка предзагружена
                                    ( this.props.newPostCard.creationData.postCardPreview.image.angle !== null &&
                                    this.props.newPostCard.creationData.postCardPreview.image.is_vertical !== null &&
                                    !this.props.newPostCard.creationData.postCardPreview.image.is_vertical
                                                                                            ? 'card-preview_h' : '' )}>
                            <div className="card-preview__title">Предварительный просмотр</div>
                            {!this.props.isPostCardUpdating ?
                                <div className="card-preview__wrapper">
                                    <div className="card-preview__wrapper__item">

                                        <div className="card-preview__face">
                                            <div className="card-preview__face__image"
                                                 onClick={() => {
                                                     this.onClickFaceImage( this.props.newPostCard.creationData.postCardPreview.face_url );
                                                 }}
                                                 style={{backgroundImage:
                                                    "url(" + this.props.newPostCard.creationData.postCardPreview.face_url + ")"}} />
                                            <div className="card-preview__about">
                                                <div className="card-preview__about__item">
                                                    <div className="card-preview__about__title">Формат</div>
                                                    <div className="card-preview__about__text">
                                                        {this.props.newPostCard.creationData.postCardPreview.paper_format.id === 1 ?
                                                            'A5 15 х 21 см' : ''}
                                                        {this.props.newPostCard.creationData.postCardPreview.paper_format.id === 2 ?
                                                            'A6 10 х 15 см' : ''}
                                                    </div>
                                                </div>
                                                <div className="card-preview__about__item">
                                                    <div className="card-preview__about__title">Лицевая сторона</div>
                                                    <div className="card-preview__about__text">
                                                        {this.props.newPostCard.creationData.postCardPreview.paper_type.title}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-preview__wrapper__item">

                                        <div className="card-preview__back">
                                            <div className="card-preview__back__image"
                                                 onClick={() => {
                                                     this.onClickBackImage( this.props.newPostCard.creationData.postCardPreview.back_url );
                                                 }}
                                                 style={{backgroundImage:
                                                    "url(" + this.props.newPostCard.creationData.postCardPreview.back_url + ")"}} />
                                        </div>

                                    </div>
                                </div>  :
                                <div className="card-preview__loader" />}
                        </div> : <div />}
                    </div>
                </div>

                <div className="clearfix" />

                {this.props.isValidAddressesForm &&
                    ( !this.props.newPostCard.creationData.postCardPreview ||
                    this.props.newPostCard.creationData.postCardPreviewErrors )
                        ?   <a href="/" className="button"
                               onClick={( event ) => {
                                event.preventDefault();
                                this.props.onClickNext();
                            }}>Далее</a> : <span />}

                {!!this.props.newPostCard.creationData.postCardPreview && !this.props.isPostCardUpdating
                    ?   <PaymentButton
                            href="/" wrapperClassName="fright" className="button"
                            postCard={this.props.newPostCard.creationData.postCardPreview}
                            isLogged={this.props.isLogged} isNotLoggedAction={this.props.onPaymentClickIfNotLogged}
                            onSuccessPayment={() => {}} />
                    :   <span />}

                {this.state.isPopupOpen ? this.renderPopup() : <div />}
            </div>
        );
    }

    renderMobile() {
        return (
            <div className="card-fields-part clearfix">
                <div className="card-fields-part__fields">
                    <div className="card-fields-part__fields__inner">

                        <a href="/" className="button width-full mb37"
                           onClick={( event ) => {
                            event.preventDefault();
                            this.props.onClickBack();
                        }}>Назад</a>

                        <div className="card-fields-part__fields__recipient">
                            <div className="card-fields-part__title">Кому</div>
                            <RecipientForm
                                newPostCard={this.props.newPostCard}
                                onChangeRecipient={this.props.onChangeRecipient} />
                        </div>
                        <div className="card-fields-part__fields__sender">
                            <div className="card-fields-part__title">От кого</div>
                            <SenderForm
                                newPostCard={this.props.newPostCard}
                                onChangeSender={this.props.onChangeSender} />
                        </div>

                        {this.props.isValidAddressesForm &&
                            ( !this.props.newPostCard.creationData.postCardPreview ||
                            this.props.newPostCard.creationData.postCardPreviewErrors )
                                ?   <a href="/" className="button width-full"
                                        onClick={( event ) => {
                                        event.preventDefault();
                                        this.props.onClickNext();
                                    }}>Далее</a> : <span />}
                    </div>
                </div>

                <div className="card-fields-part__preview">
                    <div>
                        {this.props.newPostCard.creationData.postCardPreview ?
                        <div className={"card-preview " +
                                                                                    //null если открытка предзагружена
                                    ( this.props.newPostCard.creationData.postCardPreview.image.angle !== null &&
                                    this.props.newPostCard.creationData.postCardPreview.image.is_vertical !== null &&
                                    !this.props.newPostCard.creationData.postCardPreview.image.is_vertical
                                                                                            ? 'card-preview_h' : '' )}>
                            <div className="card-preview__title">Предварительный просмотр</div>
                            {!this.props.isPostCardUpdating ?
                                <div className="card-preview__wrapper">
                                    <div className="card-preview__wrapper__item">

                                        <div className="card-preview__face">
                                            <div className="card-preview__face__image"
                                                 onClick={() => {
                                                     this.onClickFaceImage( this.props.newPostCard.creationData.postCardPreview.face_url );
                                                 }}
                                                 style={{backgroundImage:
                                                    "url(" + this.props.newPostCard.creationData.postCardPreview.face_url + ")"}} />
                                        </div>

                                    </div>
                                    <div className="card-preview__wrapper__item">

                                        <div className="card-preview__back">
                                            <div className="card-preview__back__image"
                                                 onClick={() => {
                                                     this.onClickBackImage( this.props.newPostCard.creationData.postCardPreview.back_url );
                                                 }}
                                                 style={{backgroundImage:
                                                    "url(" + this.props.newPostCard.creationData.postCardPreview.back_url + ")"}} />
                                            <div className="card-preview__about">
                                                <div className="card-preview__about__item">
                                                    <div className="card-preview__about__title">Формат</div>
                                                    <div className="card-preview__about__text">
                                                        {this.props.newPostCard.creationData.postCardPreview.paper_format.id === 1 ?
                                                            'A5 15 х 21 см' : ''}
                                                        {this.props.newPostCard.creationData.postCardPreview.paper_format.id === 2 ?
                                                            'A6 10 х 15 см' : ''}
                                                    </div>
                                                </div>
                                                <div className="card-preview__about__item">
                                                    <div className="card-preview__about__title">Лицевая сторона</div>
                                                    <div className="card-preview__about__text">
                                                        {this.props.newPostCard.creationData.postCardPreview.paper_type.title}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {!!this.props.newPostCard.creationData.postCardPreview && !this.props.isPostCardUpdating
                                            ?   <PaymentButton href="/" wrapperClassName=""
                                                               className="button pl5 pr5 width-full"
                                                               postCard={this.props.newPostCard.creationData.postCardPreview}
                                                               isLogged={this.props.isLogged}
                                                               isNotLoggedAction={this.props.onPaymentClickIfNotLogged}
                                                               onSuccessPayment={() => {}} />
                                            :   <span />}

                                    </div>
                                </div>  :
                                <div className="card-preview__loader" />}
                        </div> : <div />}
                    </div>
                </div>

                {this.state.isPopupOpen ? this.renderPopup() : <div />}
            </div>
        );
    }

    render() {
        return window.isMobile || window.isTablet ? this.renderMobile() : this.renderDesktop();
    }
}